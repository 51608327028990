body{font-family: 'Poppins', sans-serif; color: #000000;}
ul{margin: 0; padding: 0; list-style: none;}
figure{margin:0;}
img{max-width: 100%; height: auto;}
a {display: inline-block;-moz-transition:all 0.5s ease 0s;-ms-transition:all 0.5s ease 0s;-o-transition:all 0.5s ease 0s;-webkit-transition:all 0.5s ease 0s;transition:all 0.5s ease 0s;text-decoration: none;}
a:hover{text-decoration: none !important;}

.btn-primary{background-color:#0A0F2E; border-color:#0A0F2E;  color: #ffffff; font-size: 18px; padding: 10px 20px; border-radius: 6px;}
.btn-primary:active, .btn-primary:focus, .btn-primary:hover{background-color:#ffffff; border-color:#ffffff; color: #000000; box-shadow: none;}
button:focus:not(:focus-visible){outline: 0; box-shadow: none;}

.btn-secondary{background-color:#ffffff; border-color:#ffffff;  color: #0A0F2E; font-size: 18px; padding: 10px 20px; border-radius: 6px;}
.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover{background-color:#0A0F2E; border-color:#0A0F2E; color: #ffffff; box-shadow: none;}
button:focus:not(:focus-visible){outline: 0; box-shadow: none;}

header{background-color: #0A0F2E; position: relative;}
header:after{position:absolute; content: ""; left: 0; right: 0; bottom: 0; width: 100%; height: 3px; margin: auto; background:  linear-gradient(90deg, rgba(202, 23, 68, 0) 0%, #CA1744 49.71%, rgba(202, 23, 68, 0) 100%);}
.header{padding: 10px 0; display: flex; align-items: center; justify-content: space-between;}
.logo img{max-width: 200px;}
.header .navbar{padding: 0;}
.header .navbar-expand-lg .navbar-nav{align-items: center;}
.header .navbar-expand-lg .nav-item{margin-right: 30px;}
.header .navbar-expand-lg .nav-item:last-child{margin-right: 0;}
.header .navbar-expand-lg .navbar-nav .nav-link{color: #ffffff; padding: 0; font-size: 16px;}
.header .navbar-expand-lg .navbar-nav .nav-link:hover{color: #ffffff;}

.header .navbar-collapse .dropdown-menu[data-bs-popper] {right: 0; left: auto; padding: 0;}
.header .navbar-collapse .dropdown-menu:after{width: 0; height: 0; border-left: 8px solid transparent; border-right: 8px solid transparent; border-bottom: 8px solid #ffffff; 
position: absolute; top: -8px; right: 5px; content: '';}
.header .navbar-collapse .dropdown-menu .dropdown-item{padding: 8px 20px;}
.header .dropdown-toggle{position: relative;}
.header .dropdown-toggle::after{border: none; height: 8px; width: 20px; background: url("../images/dropdown-arrow.png") no-repeat; background-position: right center; vertical-align: middle;}
.container-fluid{padding-left: 30px; padding-right: 30px;}
.banner-section{background-image: url("../images/bannerbg.jpg"); background-repeat: no-repeat; background-size: cover;}
.banner-content{display: flex; justify-content: space-between; align-items: center; padding: 0 30px; min-height: 275px;}
.left-section{display: flex; 	width: 70%; align-items: center;}
.left-section > figure{height: 195px; width: 195px; border-radius: 13px; background-color: #ffffff; display: flex; align-items: center; justify-content: center; flex-wrap: wrap;}
.game-btn{display: flex; align-items: center;}
.right-section{display: flex; align-items: center; justify-content: flex-end;}



footer{background:linear-gradient(180deg, #0B1449 0%, #090F35 100%); position: relative;}
footer .container-fluid{position: relative; z-index: 1; padding: 0 30px;}
footer:after{position: absolute; content:''; right: 0; bottom: 0; height: 100%; width: 100%; background-image: url("../images/footerbg.png"); background-repeat: no-repeat; background-position: bottom right;}
.topfooter { padding: 30px 0; display: flex; justify-content: center;}
.topfooter h4{font-size: 20px; line-height: 26px; text-transform: uppercase; margin: 0 0 15px; color: #ffffff;}
.topfooter h4.mobileheading{display:none;}
.topfooter ul li{display: block; padding: 0 0 10px; font-size: 16px;}
.topfooter ul li a{color: #8890BE;}
.topfooter ul li a:hover{color: #ffffff;}
.about-footer{width: 25%; padding-right: 30px;}
.about-footer p {font-size: 16px; line-height: 26px; max-width: 320px; color: #ffffff;}
.link, .about, .get-started, .more{width: 18.75%;}
.social_links {display: flex; align-items: center;}
.social_links li{margin: 0 15px 0 0; font-size: 20px;}
.social_links li a{color: #ffffff;}
.copyyright {margin: 0; text-align: center; padding: 20px 0px; font-size: 16px; line-height: 26px; color: #ffffff; border-top: 1px solid rgba(215,215,215,0.25);}


#nav-menus span{display:block;position:absolute;height:2px;width:100%;background:#ffffff;border-radius:0px;opacity:1;left:0;-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg); -webkit-transition:.25s ease-in-out;-moz-transition:.25s ease-in-out;-o-transition:.25s ease-in-out;transition:.25s ease-in-out;}
#nav-menus span:nth-child(1){top:0px;}
#nav-menus span:nth-child(2),#nav-menus span:nth-child(3){top:6px;}
#nav-menus span:nth-child(4){top:12px;}
#nav-menus.open span:nth-child(1){top:10px;width:0%;left:50%;}
#nav-menus.open span:nth-child(2){-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);}
#nav-menus.open span:nth-child(3){-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-o-transform:rotate(-45deg);transform:rotate(-45deg);}
#nav-menus.open span:nth-child(4){top:18px;width:0%;left:50%;}
.navbar-toggler{position: absolute; right: 0; top: -5px; border: none; height: 14px; width: 24px; padding: 0; z-index: 10;}
.navbar-toggler:focus{border: none; box-shadow: none;}


.dashboard-section{display: flex; background: linear-gradient(236.28deg, #CA1744 4.07%, #0B1449 75.27%);}
.gamebar-btn{width: 34px; height: 34px; background: #FFFFFF; box-shadow: 0px 7px 80px rgba(0, 0, 0, 0.07), 0px 2.6963px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 0.57037px 6.51852px rgba(0, 0, 0, 0.0274815); position: absolute; right: 4px; top: 8px; border-radius: 50%; padding: 10px; display: flex; align-items: center; justify-content: center;}
.menubar{position: relative;}
.gamebar-btn span{display:block;position:absolute;height:2px;width:24px;background:#0A0F2E;border-radius:0px;opacity:1;left:0;-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg); -webkit-transition:.25s ease-in-out;-moz-transition:.25s ease-in-out;-o-transition:.25s ease-in-out;transition:.25s ease-in-out;}
.gamebar-btn span:nth-child(1){top:0px;}
.gamebar-btn span:nth-child(2){top:6px;}
.gamebar-btn span:nth-child(3){top:12px;}
.gamebar-btn.open span:nth-child(1){top:10px;width:0%;left:50%;}
.gamebar-btn.open span:nth-child(2){-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg); top:5px;}
.gamebar-btn.open span:nth-child(3){-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-o-transform:rotate(-45deg);transform:rotate(-45deg); top:5px;}
.left-dashboard{background-color: #0A0F2E; padding: 50px 0 20px 15px; width: 329px; position: fixed; left:-329px; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; z-index: 9; overflow-y: auto; height: calc(100% - 70px)}
.dashboard-section.open .left-dashboard{left: 0; width: 329px;}
.left-dashboard ul{overflow-y: auto; padding: 0px 0 0;}
.left-dashboard ul li{padding: 4px 0;}
.left-dashboard ul li a{display: flex; align-items: center; padding: 11px 15px; border-radius: 8px 0 0 8px; min-height: 60px;}
.left-dashboard ul li a span{font-weight: 400; font-size: 16px; line-height: 27px; color: #8890BE; display: none;}
.left-dashboard ul li a:hover, .left-dashboard ul li a.active{background-color:#FAFAFA;}
.left-dashboard ul li a:hover span, .left-dashboard ul li a.active span{color: #0A0F2E; font-weight: 500;}
.left-dashboard ul li a figure{display: flex; height: 38px; width: 38px; border-radius: 50%; overflow: hidden; margin-right: 15px;}
.dashboard-section.open .left-dashboard ul li a span{display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px;}

.right-dashboard{width: calc(100% - 0px); padding: 20px 20px;}
.right-dashboard.open{width: calc(100% - 329px);}
.findgamebox{position: relative; margin: 0 0 20px; padding-right: 15px;}
.findgamebox .searchimg{position: absolute; left: 10px; top: 0; bottom: 0; height: 25px; width: 25px; margin: auto;}
.findgamebox .form-control{border: none; padding-left: 40px; height: 50px; font-size: 17px; line-height: 26px; color: #B3B3B3; color: #ffffff; background-color: transparent; border-bottom: 1px solid rgba(255,255,255,0.5); border-radius: 0;}
.findgamebox .form-control:focus{box-shadow: none;}
.findgame-section{display: flex; justify-content: space-between; align-items: center; padding:0; width: 100%; margin: 0 0 20px;}
.findgame-section .switchbtn{position: absolute; padding: 0px 15px 0 0; width: auto; top: 10px; left: 15px;}
.switchbtn .user-profile{display: none; cursor: pointer;}
.user-profile .verified{right: 10px; bottom: 0; max-width: 18px;}
.gameprofileclose{display: none;}
.findgame-section .switchbtn:after{position: absolute; content: ""; right: 20px; top: 4px; bottom: 0; margin: auto; width: 16px; height: 9px; background: url("../images/bottom-arrow-blue.png") no-repeat; background-size: 10px;}
.findgame-section select.form-control{height: auto; width: auto; background: #ffffff; border: 1px solid #ffffff; border-radius: 5px; color: #0A0F2E; font-size: 12px;}
.findgame-section select.form-control:focus{box-shadow: none;}

.block-section{display: flex; width: 100%; justify-content: space-between; margin: 0 0 20px;}
.whitebox{background: #FFFFFF; -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); border-radius: 10px; padding: 20px 20px 10px 20px; height: 100%;}
.boxheading{display: flex; align-items: center; justify-content: space-between; margin: 0 0 15px;}
.boxheading h4{font-weight: 600; font-size: 22px; line-height: 33px; color: #000000; margin:0;}
.border-btn{height: 53px; background: #FFFFFF; border: 1px solid #0A0F2E; border-radius: 5px; padding: 13px 20px; text-align: center; text-decoration: none; font-weight: 500; font-size: 18px; line-height: 27px; color: #0A0F2E;}
.border-btn:hover{background: #0A0F2E; border: 1px solid #0A0F2E; color: #ffffff;}
.left-block{width: 70%; padding: 0;}
.right-block{width: 30%; padding: 0 0 0 28px;}

.quickrow .right-block{padding: 0 28px 0 0;}

.gamestack .whitebox{margin-top: -10px;}
.matchbar{background: #0A0F2E; border-radius: 10px 10px 0px 0px; display: flex; align-items: center; justify-content: space-between; padding: 18px 18px 28px 22px;}
.gamestack .game-btn li{width: auto; padding: 0;}
.matchinfo{position: relative; padding: 0 0 0 78px; min-height: 52px;}
.matchinfo figure{width: 52px; height: 52px; border-radius: 50%; overflow: hidden; position: absolute; top: 0; left: 0;}
.matchinfo strong{font-weight: 600; font-size: 22px; line-height: 33px; color: #FFFFFF; display: block;}
.matchinfo strong a{color: #FFFFFF; text-decoration: none;}
.matchinfo strong a img{margin: -4px 0 0 8px;}
.matchinfo span{font-weight: 400; font-size: 16px; line-height: 24px; color: #FFFFFF; display: block;}
.edit-team{padding: 0 42px;}
.edit-team a{text-decoration: none; font-weight: 500; font-size: 16px; line-height: 24px; text-align: right; color: #FFFFFF;}
.edit-team a img{margin: 0 12px 0 0;}

.game-heading{display: flex; align-items: center; justify-content: space-between; margin:0; padding: 0 0 10px 50px; position: relative;}
.game-heading > a.gamebar-btn-open{position: absolute; left: 0; top: 3px;}
.game-heading h1{font-weight: 600; font-size: 26px; line-height: 39px; color: #FFFFFF; margin: 0;}
.game-heading > a{text-decoration: none; font-weight: 500; font-size: 16px; line-height: 24px; text-align: right; color: #444444;}
.game-heading > a:hover{color: #0A0F2E;}

.right-block .gameprofile{display: block;}
.gameprofile .whitebox{padding: 0;}
.gamevideo{position:relative; border-radius: 10px 10px 0 0; overflow: hidden;}
.gamevideo > figure > img{height: 132px; object-fit: cover; width: 100%;}
.playbtn{position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; height: 58px; width: 58px;}
.user-profile{width: 105px; height: 105px; border-radius: 50%; background: #FFFFFF; -webkit-box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.07); box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.07); display: flex; align-content: center; justify-content: center; flex-wrap: wrap; position: relative; left: 0; right: 0; top: 0; margin: -54px auto 20px;}
.verified {position: absolute; right: 5px; bottom: -2px; max-width: 25px;}
.profile-content{padding: 0 10px; text-align: center;}
.profile-content h4{font-weight: 600; font-size: 26px; line-height: 39px; text-align: center; color: #000000; margin: 0 0 15px;}
.profile-content a.border-btn{margin: 0 2px 5px; padding: 10px 10px; height: 46px; font-size: 16px;}
.promocode{width: 250px; margin:10px auto 10px; position: relative;}
.promocode .form-control{border: 1px solid #0A0F2E; border-radius:5px; height: 44px;}
.promocode .form-control:focus{border: 1px solid #0A0F2E;box-shadow: none;}
.promocode .border-btn{position: absolute; top: 0; right: 0; padding: 7px 15px; height: 44px; background-color: #0A0F2E; color: #ffffff;}
.promocode .border-btn:hover{background-color: #ffffff; color: #0A0F2E;}
.admindetail{text-align: center; padding: 5px 0px 0;}
.admindetail span{display: inline-block; padding: 0 5px 10px; color: #444444; font-size:15px; font-weight: 500; position: relative;}
.admindetail span i{margin-right: 8px; font-size: 16px;}

.social-link{display: flex; align-content: center; justify-content: center; padding: 10px 0 10px; border-bottom: 1px solid #F2F2F2;}
.social-link li{padding: 0 8px;}
.profile-content > p{ /*font-weight: 400; font-size: 16px; line-height: 24px; text-align: center; color: #444444;*/ margin:10px 0;}
.profile-content > p a{color: #1877F2;}
.other-link{display: flex; align-content: center; justify-content: center; padding: 20px 0 20px; border-top: 1px solid #F2F2F2;}
.other-link li{padding: 0 10px; position: relative;}
.other-link li:after{position: absolute; content: ""; height: 17px; top: 0; bottom: 0; margin: auto; right: 0; width: 1px; background-color: #dcdcdc;}
.other-link li:last-child:after{display: none;}
.other-link li a{text-decoration: none; font-weight: 500; font-size: 18px; line-height: 27px; color: #0A0F2E;}

.bottom-link{display: flex; align-content: center; justify-content: center; background: #0A0F2E; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); border-radius: 10px; margin: 0px -10px;}
.bottom-link li{padding: 0 15px; position: relative; width: 50%;}
.bottom-link li:after{position: absolute; content: ""; height:100%; top: 0; bottom: 0; margin: auto; right: 0; width: 1px; background-color: #8890BE; opacity: 0.2;}
.bottom-link li:last-child:after{display: none;}
.bottom-link li a{text-decoration: none; font-weight: 500; font-size: 18px; line-height: 27px; color: #ffffff; padding: 15px 0;}
.bottom-link li.active{background-color: #4f2a8c; border-radius: 0 10px 10px 0;}
.bottom-link li a.active{background: linear-gradient(42.98deg, #FEE411 11.93%, #FEDB16 17.93%, #FEC125 27.93%, #FE983D 40.67%, #FE5F5E 55.51%, #FE2181 69.85%, #9000DC 127.77%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; text-fill-color: transparent;} 
.gamestack{margin: 0 0 25px;}
.gamestack ul{display: flex; flex-wrap: wrap;}
.gamestack ul li{width: 20%; padding:20px 20px 30px;}
.gamestack ul li strong{display: block; font-weight: 600; font-size: 26px; line-height: 39px; color: #0A0F2E;}
.gamestack ul li span{display: block; font-weight: 400; font-size: 14px; line-height: 21px; color: #444444;}

.topplayerlist ul{height: 288px;}
.topplayerlist ul li {position: relative; padding: 5px 5px 5px 63px; margin: 0 0 10px; background-color: #f4f3f6; border-radius: 100px;}
.topplayerlist ul li figure{height: 42px; width: 42px; border-radius: 50%; overflow: hidden; position: absolute; top: 5px; left: 10px;}
.topplayerlist ul li h5{font-weight: 600; font-size: 14px; line-height: 21px; color: #000000; margin: 0;}
.topplayerlist ul li h5 a{color: #000000; text-decoration: none;}
.topplayerlist ul li span{font-weight: 400; font-size: 14px; line-height: 21px; color: #444444; display: block;}
.topplayerlist ul li strong{font-weight: 400; font-size: 14px; line-height: 21px; text-align: right; color: #000000; position: absolute; right: 10px; top: 0; bottom: 0; margin: auto; display: flex; align-items: center;}


.dashboardtab .nav-tabs{border-bottom: 2px solid #F2F3F3; margin: 0 0 15px;}
.dashboardtab .nav-tabs .nav-link{padding-top: 0; border: none; font-weight: 500; font-size: 16px; line-height: 24px; text-align: center; color: #444444; margin: 0; position: relative; width: 50%; padding-right: 10px; padding-left: 10px;}
.dashboardtab .nav-tabs .nav-link.active{color: #0A0F2E;}
.dashboardtab .nav-tabs .nav-link.active:after{position: absolute; content: ""; height: 2px; left: 0; bottom: -2px; width: 100%; background-color: #0A0F2E;}
.dashboardtab.fixturetab .nav-tabs .nav-link{width: 33.33%;}

.sponsortop{display: flex; align-items: center; justify-content: space-between; padding: 1px;}
.sponsorinfo{position: relative; padding: 15px 0 0 90px; min-height: 80px;} 
.sponsorinfo figure{width: 79px; height: 79px; overflow: hidden; border-radius: 50%; background: #000000; display: flex; flex-wrap: wrap; align-content: center; justify-content: center; position: absolute; left: 0; top: 0; border: 1px solid #000000;} 
.sponsorinfo strong{font-style: normal; font-weight: 600; font-size: 16px; line-height: 24px; color: #000000;}
.sponsor-social{display: flex; align-content: center; margin: 5px 0 0;}
.sponsor-social li{padding: 0 0 0 5px;}
.sponsor-social li a{width: 21px; height: 21px; border-radius: 50%; background: #000000; color: #ffffff; display: flex; flex-wrap: wrap; align-content: center; justify-content: center; font-size: 12px;}
.sponsorslide p{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444; margin: 20px 0 0;}
.sponsor-carousel, .sponsor-carousel .carousel{height: 100%;}
.sponsor-carousel .carousel-indicators{bottom: 60px; margin-bottom: 0;}
.sponsor-carousel .carousel-indicators button{width: 8px; height: 8px; background: #D9D9D9; border-radius: 50%; padding: 0; text-indent: inherit; border: none;}
.sponsor-carousel .carousel-indicators button.active{width: 8px; height: 8px; background: #000000;}

.articlelist ul{height: 270px;}
.articlelist ul li{position: relative; padding: 6px 0 6px 87px; min-height: 76px;}
.articlelist ul li figure{position: absolute; left: 0; top: 0; width: 70px; height: 70px; border-radius: 6px; overflow: hidden;}
.articlelist ul li p{font-weight: 500; font-size: 14px; line-height: 21px; color: #000000; margin: 0;}
.articlelist ul li a{text-decoration: none; font-weight: 400; font-size: 16px; line-height: 24px; color: #1877F2;}


.playbox ul{display: flex; flex-wrap: wrap; padding: 30px 0 30px;}
.playbox ul li{width: 33.33%; padding: 0 30px 0; text-align: center; position: relative;}
.playbox li:after{position: absolute; content: ""; height: 100%; top: 0; bottom: 0; margin: auto; right: 0; width: 1px; background-color: #F2F2F2;}
.playbox li:last-child:after{display: none;}
.playbox ul li h5{font-weight: 600; font-size: 18px; line-height: 27px; text-align: center; color: #000000; margin:20px 0 15px;}
.playbox ul li p{font-weight: 400; font-size: 16px; line-height: 24px; text-align: center; color: #444444; margin: 0;}

.quicklink ul li{ /* font-weight: 500; font-size: 18px;*/ line-height: 46px; padding: 3px 0; position: relative;}
.quicklink ul li a{color: #0A0F2E; text-decoration: none; display: block; position: relative;}
.quicklink ul li a:after{position: absolute; content: ""; right: 0; top: 0; bottom: 0; margin: auto; height: 24px; width: 24px; background: url("../images/right-arrow.svg") no-repeat;}
.aboutgame span{font-weight: 700; position: absolute; right: 0; top: 0; bottom: 0; margin: auto; color: #0A0F2E;}

.gameinfo ul{display: flex; flex-wrap: wrap;}
.gameinfo ul li{width: 33.33%; padding:50px 20px 0px;}
.gameinfo ul li.customli{width: 16.33%; padding:50px 20px 0px;}
.gameinfo ul li strong{display: block; font-weight: 600; font-size: 26px; line-height: 39px; color: #0A0F2E;}
.gameinfo ul li span{display: block; font-weight: 400; font-size: 14px; line-height: 21px; color: #444444;}

.stopscroll{position: fixed; left: 0; top: 0; width: 100%; height: 100%;}


.gamemenu-link{position: fixed; left: -300px; top: 0; width: 300px; height: 100%; background-color: #ffffff; padding: 15px; z-index: 99; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; overflow-y: auto;}
.gamemenu-link .close img{max-width: 24px;}
.gamemenu-link.open{left: 0;}
.gamemenu-link .close{color: #000000; display: block; position: absolute; top: 2px; right: 10px; font-size: 24px;}
.gamemenu-link ul li{display: block; padding: 10px 0;}
.gamemenu-link ul li a{display: inline-block; color: #000000;}
.gamemenu-link ul li a.active{}
.gamemenu-link ul li a:hover{color: #0A0F2E;}
.gamemenu-overlay{position: fixed; left: 0;  top: 0; height: 100%; width: 100%; background-color: rgba(0,0,0,0.7); opacity: 0; visibility: hidden; z-index: 9;}
.gamemenu-overlay.active{opacity: 1; visibility: visible;}
.mobileads{display: none;}


.playercardlist ul{display:flex; flex-wrap: wrap; margin: 0 -10px; padding-top: 20px;}
.playercardlist ul li{width:25%; padding:60px 10px 20px;}
.playercardbox{background-color:#ffffff; position: relative; border-radius: 10px; padding:55px 10px 20px 10px; min-height: 100px;text-align: center;}
.playercardimg figure{position: absolute; left: 0; top: -50px; height: 90px; width: 90px; overflow: hidden; background-color: #ffffff; text-align: center; /*padding: 5px;*/border-radius: 50%;right: 0;margin: auto; -webkit-box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.07); box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.07);}
.playercardimg figure img{max-height: 90px;}
.followicon{color: #CA1744; position: absolute; right: 7px; top: 3px; text-decoration: none;}
.followicon:hover{color:#0A0F2E;}
.viewicon{color: #CA1744; position: absolute; left: 7px; top: 3px; text-decoration: none;}
.viewicon:hover{color:#0A0F2E;}
.playercardbox h4 {font-weight: 700; font-size: 16px; line-height: 20px; color: #0b1449; margin: 0px 0px 10px 0px;}
.playercardbox h4 a{color: #0b1449;}
.playercardbox h6{font-weight: 400; font-size: 14px; line-height: 20px; margin: 0px 0px 7px 0px; color: #000000;}
.playercardbox span{color: #000000; font-size: 14px; font-weight: 500; display: inline-block; padding: 0px 10px; position: relative;}
.playercardbox span:not(:first-of-type):after{position: absolute; content: ""; left: -6px; top: 0; bottom: 0; margin: auto; height: 6px; width: 6px; border-radius: 50%; background-color: #666666;}
.playercardbox .btn-primary{font-size: 14px; padding: 8px 15px; display: block; max-width: 112px; margin: 10px auto 0;}

.gamemember.playercardlist ul li{padding: 20px 10px 20px;}
.gamemember .playercardbox{padding: 10px 0px 10px 100px; min-height: inherit; text-align: left; border-radius: 30px 5px 5px 30px;}
.gamemember .playercardimg figure {left: -4px; right: auto; bottom: auto; top: -7px; -webkit-box-shadow:3px 0px 0px rgba(0, 0, 0, 0.07); box-shadow:3px 0px 0px rgba(0, 0, 0, 0.07);}
.menulogo{position: absolute; top: 8px; left: 15px;}
.menulogo img{max-width: 90px;}

#gamepass .btn-close{background: none; padding: 0; opacity: 1; position: absolute; right: 10px; top: 10px; height: 22px; width: 22px;}
.gamepass-detail{padding: 30px 30px; text-align: center;}
.gamepass-detail h2{font-weight: 600; font-size: 26px; line-height: 39px; color: #0A0F2E; margin: 0;}
.gamepass-detail p{font-weight: 400; font-size: 16px; line-height: 24px; text-align: center; color: #444444; margin: 15px 0;}
.gamepass-detail .btn-primary{margin-top: 20px; background-color: #4f2a8c;}
.gamepass-detail .btn-primary:hover, .gamepass-detail .btn-primary:active, .gamepass-detail .btn-primary:focus{background-color: #4f2a8c; border-color: #4f2a8c; color: #ffffff;}

.modal-header{background-color: #CA1744; color: #ffffff; position: relative;}
.modal-header .btn-close{background: none; padding: 0; opacity: 1; height: 22px; width: 22px; position: absolute; right: 15px; top: 0; bottom: 0; margin: auto;}
.modal-header .btn-close img{vertical-align: inherit;}
#entryfee .modal-body{padding: 30px;}
#entryfee .modal-body p{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444; margin: 0 0 20px;}
#entryfee .modal-body ul{margin: 0 0 30px;}
#entryfee .modal-body ul li{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444; font-style: italic; padding: 0 0 10px;}
.paidcheck{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444;}


#pointsystem .modal-body, #datemodal .modal-body{max-height: 623px; overflow-y: auto; padding-top: 0;}
.modal-body .searchfilter{padding-right: 0; padding-left: 0;}
#pointsystem .table{margin: 0;}
.modal-body .table tr th, .modal-body .table tr td{padding: 12px 15px; border:none; border-bottom: 6px solid #ffffff; border-top: 6px solid #ffffff;}
.table thead tr th:first-child, .table tbody tr td:first-child{text-align: left; border-radius: 0px 0 0 0px;}
.table thead tr th:last-child, .table tbody tr td:last-child{border-radius: 0 0px 0px 0;}
#toptradein .modal-body, #toptradeout .modal-body, #topplayeroverall .modal-body, #topplayergameweek .modal-body{padding-top: 0;}
.table > :not(caption) > * > *{box-shadow: none;}


.datefilter ul{display: flex; padding: 20px 0px; margin: 0 -10px;}
.datefilter ul li{width: 33.33%; padding: 0 10px;}
.datefilter ul li label{font-weight: 600; color: #0A0F2E; margin: 0 0 5px;}
.datefilter ul li .form-control{box-shadow: none;}

.ruleaccordion {background-color: #ffffff; border-radius: 10px; padding: 30px 15px; margin: auto; max-width: 1000px;}
.ruleaccordion .accordion-item{margin-bottom: 15px; border-radius: 20px;}
.ruleaccordion .accordion-item:last-of-type .accordion-button.collapsed {border-bottom-right-radius:20px; border-bottom-left-radius:20px;}
.ruleaccordion .accordion-item:not(:first-of-type){border-top:1px solid rgba(0,0,0,.125);}
.ruleaccordion .accordion-item:first-of-type{border-radius: 20px;}
.ruleaccordion .accordion-item:last-of-type{border-radius: 20px;}
.ruleaccordion .accordion-item:first-of-type .accordion-button{border-top-left-radius:20px; border-top-right-radius:20px;}
.ruleaccordion .accordion-button{font-weight: 600; font-size: 16px; border-radius: 20px; padding-right:25px;}
.ruleaccordion .accordion-button:not(.collapsed){color: #ffffff; background-color: #0A0F2E;}
.ruleaccordion .accordion-button::after{background-image:none; position: absolute; content: "+"; right: 10px; top: 10px; font-size: 28px; height: auto; width: auto; color: #444444; transform: rotate(0); font-weight: 300;}
.ruleaccordion .accordion-button:not(.collapsed)::after{background-image:none; color: #ffffff; content: "-";}
.rulelist p{font-weight: 400; font-size: 14px; line-height: 20px; color: #444444; margin: 0;}
.rulelist ul{margin: 0 0 5px;}
.rulelist ul li ul{margin: 10px 0 0;}
.rulelist ul li{font-weight: 400; font-size: 14px; line-height: 20px; color: #444444; padding: 0 0 10px 15px; position: relative;}
.rulelist ul li:after{position: absolute; content: ""; left: 0; top: 7px; height: 6px; width: 6px; border-radius: 50%; background-color: #444444;}

.powertable.table{margin: 20px 0;}
.powertable.table tbody tr td{padding: 8px 12px; text-align: left; background-color: #ffffff;}
.powertable.table tbody tr:last-child td{border: none;}
.powertable.table thead tr th{text-align: left; background-color: #0A0F2E; color: #ffffff;}

.sponsor_list{display: flex; flex-wrap: wrap;}
.sponsor_list >  li{width: 33.33%; padding: 0 10px 20px;}
.sponsor_list .sponsorslide{background: #FFFFFF; -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); border-radius: 10px; padding: 15px 15px; text-align: left; position: relative; overflow: hidden;}
.sponsor_list .sponsorslide strong a{color: #000000;}
.sponsor_list .sponsorslide .border-btn{height: auto; padding: 6px 14px; font-size: 14px; line-height: 18px;}
.sponsor_list .sponsorslide p{display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; font-size: 14px;
line-height: 22px;}
.sponsor_list .social-link{border: none; padding: 0; justify-content: flex-start;}
.sponsor_list .social-link li{padding: 0 8px 0 0;}
.sponsor_list .social-link li a img{width: 21px; height: 21px; border-radius: 50%;}
.featuretag{background-color: #198754; color: #ffffff; position: absolute; top: 6px; right: -15px; font-size: 10px; padding: 2px 8px; border-radius: 0px 5px 0px 0px; -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); transform: rotate(45deg);}

.article_list{display: flex; flex-wrap: wrap; margin: 0 -15px;}
.article_list >  li{width: 33.33%; padding: 0 15px 30px;}
.article_list .articlebox{background: #FFFFFF; -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); padding:15px 15px 20px; text-align: left; border-radius: 10px; height: 100%;}
.articlebox figure{margin: 0 0 15px;}
.articlebox figure a{display: block;}
.articlebox figure img{width: 100%;}
.articlebox strong{display: block; font-style: normal; font-weight: 600; font-size: 18px; line-height: 24px; color: #000000; margin: 0 0 10px;}
.articlebox strong a{color: #000000;}
.articlebottom{display: flex; align-items: center; justify-content: space-between;}
.articlebottom .sponsor-social{margin: 0;}
.articledate{font-size: 14px; line-height: 22px; color: #444;}
.articledate .far{margin-right: 5px;}

.tradetab.nav-tabs{border-bottom: none; margin: 0 0 10px; justify-content:center;}
.tradetab.nav-tabs .nav-item{padding: 0 5px 10px;}
.tradetab.nav-tabs .nav-link{margin: 0; border: none; border-radius: 20px; background-color: #ffffff; color: #000000; padding: 10px 25px; position: relative;}
.tradetab.nav-tabs .nav-link.active{background-color: #0A0F2E; color: #ffffff;}
.tradetab.nav-tabs .nav-link.active:after{position: absolute; content: ""; left: 0; right: 0; bottom: -10px; margin: auto; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid #0A0F2E;}

.badge{font-weight: normal;}
.bg-primary{background-color: #0d6efd !important;}
.bg-secondary{background-color: #6c757d !important;}
.bg-success{background-color: #198754 !important;}
.bg-danger{background-color: #dc3545 !important;}
.bg-warning{background-color: #ffc107 !important;}
.bg-info{background-color: #0dcaf0 !important;}
.bg-light{background-color: #f8f9fa !important;}
.bg-dark{background-color: #212529 !important;}


.tradetable.table thead tr th, .tradetable.table tbody tr td{text-align: left; padding: 10px 15px;}
.tradetable.table thead tr th:first-child, .tradetable.table tbody tr td:first-child{width: 70%;}

.winnerrable.table thead tr th, .winnerrable.table tbody tr td{padding: 12px 20px; text-align: left;}
.winnerrable.table thead tr th:nth-child(1), .winnerrable.table tbody tr td:nth-child(1){width: 30px;}
.more_link{background-color: #000000; color: #ffffff; border-radius: 6px; padding: 3px 6px;}
.more_link:hover{color: #ffffff;}

.gamestatstable.table thead tr th:first-child, .gamestatstable.table tbody tr td:first-child{width: 70%; text-align: left; padding: 8px 15px;}

.fantasypoint{background-color: #4f2a8c; color: #ffffff; font-size: 12px; padding: 4px 15px; border-radius: 15px;}
.fantasypoint:hover{color: #ffffff;}

.prize-section ul{display: flex; flex-wrap: wrap; margin: 50px 0 0;}
.prize-section ul li{width: 33.33%; padding: 0 15px 30px;}
.prizebox{background-color: #ffffff; -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); border-radius: 10px; padding: 20px 18px 30px; height: 100%;}
.prizebox figure{text-align: center; margin: 0 0 30px;}
.prizebox figure img{max-height: 180px;}
.prizebox h4{font-weight: 600; font-size: 26px; line-height: 26px; color: #000000; margin: 0 0 25px; text-align:center;}
.prizebox span{display: block; font-weight: 400; font-size: 14px; line-height: 21px; color: #444444; position: relative; padding: 0 0 5px 15px;}
.prizebox span:after{position: absolute; content: ""; left: 0; top: 7px; height: 8px; width: 8px; border-radius: 50%; background-color: #545454;}


.searchfilter .custom-searchbox{position:absolute; right:55px; top:15px;}
.searchfilter .custom-searchbox .searchbox{position: absolute; right: 30px; top: -7px; width:0; opacity: 0; visibility: hidden; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.searchfilter .custom-searchbox .form-control{background: #EEF0F8; border-radius: 8px; border: none; height: 39px; padding:0px 40px 0px 12px; font-size: 14px;}
.searchfilter .custom-searchbox.open .searchbox{width: 250px; opacity: 1; visibility: visible;}
.searchfilter .custom-searchbox img{max-width: 22px;}
.closeicon{position: absolute; right: 6px;	top: 6px;}
.closeicon img{max-width: 18px;}

.modal-body .searchfilter .custom-searchbox{right: 35px;}
.modal-body .searchfilter .dropdown{right: 0; top: 15px;}
.modal-body .searchfilter .dropdown img{max-width: 24px;}

.profile-section{padding: 30px 0px;}
.profile-section .container{padding: 0; display: flex; flex-wrap: wrap; width: 100%;}
.innerwhitebox{background: #FFFFFF; -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); border-radius: 10px; padding: 20px 20px 10px 20px; margin: 20px 0 0;}
.innerwhitebox:first-child{margin: 0;}
.profilebox{width:25%; text-align: center; background-color:#0A0F2E; padding: 20px 15px; color: #ffffff; position: relative; border-radius: 10px;}
.editprofile{position: absolute; right: 10px; top: 10px; color: #ffffff; text-decoration: none;}
.editprofile:hover{color: #CA1744;}
.profilebox .profileimg{height: 150px; width: 150px; margin:0 auto 20px;overflow: hidden; border-radius: 50%; border: 3px solid #ffffff;}
.profilebox .playerlist{margin: 0 0 15px;}
.profilebox .available{font-size: 16px; position: absolute; left: 15px; top: 9px;}
.profilebox .available::after{top: 7px;}
.profilebox .playerlist select{font-size: 18px; color: #000000; display: flex; justify-content: center; background-color: #f4f3f6; width: 100%; border: 1px solid #f4f3f6; border-radius: 6px; padding: 5px 10px;}
.profilebox .playerlist select option{color: #000000;}
.profilebox .social-link{border: none; margin: 0 0 10px;}
.profilebox .social-link li{padding: 0 5px;}
.profilebox .social-link li a img{max-width: 24px;}
.profilebox .playercard{display: inline-block; background-color: #FFD43C; padding: 2px 10px; border-radius: 6px; font-weight: normal; font-size: 14px; margin-bottom: 15px;}
.profilebox .playercard a{color: #000000;}

.profilebox .profilebadge{display: inline-block; background-color: #00AA6B; padding: 2px 10px; border-radius: 6px; font-weight: normal; font-size: 14px; margin-bottom: 25px;}
.profilebox .profilebadge a{color: #ffffff;}
.profilebox > p{margin: 0 0 15px;}
.overview li{font-size: 15px; text-align: left; padding: 0 0 6px 24px; position: relative;}
.overview li i{position: absolute; left: 0; top: 5px;}
.contentbox{width:75%; padding: 0 15px; position: relative;}
.contentbox h4{font-weight: 600; font-size: 22px; line-height: 33px; color: #000000; margin: 0 0 10px;}
.contentbox .searchfilter h4{margin: 0; line-height: inherit;}
.contentbox .table{margin: 0 0 20px;}
.contentbox .table thead tr th:first-child, .contentbox .table tbody tr td:first-child{text-align: left;}
.contentbox .table tbody tr td .fa-heart{color:#CA1744;}
.contentbox .table tbody tr td a{color:#000000; text-decoration: none;}
.contentbox .table tbody tr td a.more_link{background-color: #000000; color: #ffffff;}

.profilelist{margin: 0 0 25px;}
.profilelist li{text-align: left; position: relative; padding: 6px 20px 6px 10px; font-weight: 300;background-color: #f4f3f6;margin: 5px 0px;border-radius: 20px;color: #000000;font-size: 14px;}
.profilelist li span{display: block; /* position: absolute; right: 10px; top: 5px; */ font-weight:500;}

.viewcard{display: none;}
.careersummary{display: flex; align-items: center; justify-content: center; flex-wrap: wrap; margin:25px 0 20px;}
.careersummary li{width: 20%; text-align: center; margin: 0 0 30px; position: relative;}
.careersummary li:after{position: absolute; content: ""; right: 0; top: 0; height: 100%; width: 1px; background-color: #D9D9D9; opacity: 0.6;}
.careersummary li:nth-child(5):after, .careersummary li:nth-child(9):after{display: none;}
.summarybox figure{position: relative; margin:0 auto 5px; width: 56px; height: 56px; border-radius: 50%; overflow: hidden; display: flex; align-content: center; justify-content: center; flex-wrap: wrap;}
.summarybox figure:after{position: absolute; content: ""; left: 0; top: 0; height: 100%; width: 100%; background: #000000; opacity: 0.15;}
.summarybox figure img{position: relative; z-index:1; max-width: 32px;}
.summarybox figure.circle1:after{background: #F9B942;}
.summarybox figure.circle2:after{background: #A43EF3;}
.summarybox figure.circle3:after{background: #FC417C;}
.summarybox figure.circle4:after{background: #37AAAF;}
.summarybox figure.circle5:after{background: #00AA6B;}
.summarybox strong{display: block; font-weight: 700; font-size: 18px; padding: 0 0 5px;}
.summarybox strong a{color: #000000;}
.summarybox span{display: block; font-weight: 400; font-size: 14px;}
.summarybox span a{color: #1877F2;}

.recentperformance{margin: 0;}
.claim-profile h4{font-size: 20px; margin: 0 0 15px;}
.claim-profile label{font-size: 16px; display: block; margin: 0 0 8px;}
.claim-profile .form-group{margin: 0 0 15px;}
.claim-profile .form-control:focus{box-shadow: none;}
.claim-profile .border-btn{height: auto; padding: 8px 20px;}
.playeroverview{text-align: center;}
.playeroverview h5{font-size: 16px; margin:0; font-weight: 500;}
#viewmodal .table tbody tr td:last-child{text-align: right;}
.contentbox .table.legendtable tbody tr td:first-child{text-align: center;}

.matchscorecard .contentbox{width:100%;}
.matchscorecard .innerwhite-section{padding: 20px 15px; background-color: #ffffff; position: relative;}
.innerwhite-section .tab-content.scorecardtabcontent{padding-top:0; margin-top: -5px;}


.profile-section.matchscorecard .container{display: block;}
.nopadding{padding: 0 !important;}
@media (min-width:1200px){
.container{max-width:1200px;}
.gameprofile{display: none;}
}
@media (min-width:768px){
.topfooter .collapse:not(.show) {display: block;}
}
@media (max-width:1660px){

}
@media (max-width:1365px){
.whitebox {padding: 15px 10px 10px 10px;}	
.left-block{width: 65%;}
.right-block{width: 35%; padding: 0 0 0 25px;}
.left-dashboard ul li a span {font-size: 16px; line-height: 20px;}
.dashboard-section.open .left-dashboard {width: 300px; padding: 50px 0 20px 10px;}
.left-dashboard ul li a {padding: 15px 15px 15px 10px; min-height: 65px;}
.profile-content{padding: 0 10px;}
.other-link li{padding: 0 10px;}
.other-link li a{font-size: 16px;}	
.bottom-link {margin: 0px -10px;}
.game-heading h1{font-size: 24px;}
.profile-content a.border-btn {padding: 7px 15px; height: 44px; font-size: 14px; margin: 0 2px 5px;}
.dashboardtab .nav-tabs .nav-link {font-size: 13px; padding-right: 5px; padding-left: 5px; line-height: 18px;}
.other-link {padding: 10px 0; flex-wrap: wrap;}
.other-link li{padding: 0 5px 5px; width: 50%;}	
.other-link li::after {display: none;}
.sponsorinfo{padding: 5px 0 0 66px; min-height: 50px;}
.sponsorinfo figure{width: 59px; height: 59px;}
.sponsortop .border-btn {height: 46px; padding: 10px 20px; font-size: 16px;}
}
@media (max-width:1199px){
.switchbtn .user-profile{width: 45px; height: 45px; margin: auto;position: absolute;  top: 0; right: -55px; left: auto; bottom: 0; display: block;}
.block-section {flex-wrap: wrap; margin: 0 0 25px;}
.left-block{width: 100%; padding: 0 0 0 0;}
.right-block{width: 100%; padding: 25px 0 0 0;}
.quickrow .right-block{padding: 0 0 25px 0;}	
.profile-content a.border-btn {padding: 6px 12px; height: 40px; font-size: 14px; line-height: 24px; margin: 0 2px 2px;}	
.gameprofile{width: 100%; position: fixed; top: 0; height: calc(100% - 54px); right: -100%; z-index: 12; overflow-y: auto; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.gameprofile.open{right: 0;}
.gameprofile.open .whitebox{position: relative; z-index: 1; height: auto;}
.bottom-link{margin: 0;}
.gameprofile:after{position: fixed; content:""; left: 0; top: 0; height: 100%; width: 100%; background-color: rgba(0,0,0,0.7); display: none;}
.gameprofile.open:after{display: block;}
.gameprofileclose{display: block; position: absolute; left: 5px; top: 5px; z-index: 9; width: 36px; height: 36px; background-color: #ffffff; border-radius: 50%; padding: 5px 10px;}	
.gameinfo ul li{padding: 20px 20px 20px;}
.dashboard-section.open .left-dashboard ul li a span{max-width: 155px;}
.sponsorslide p{margin: 20px 0 30px;}
.right-block.p0{padding: 0;}
.other-link li{padding: 0 15px 0px; width: auto;}
.other-link li::after{display: block;}	

.profile-content{padding: 0 10px 10px 10px;}

.gamemember.playercardlist ul li{width: 33.33%;}

.prizebox h4{font-size: 24px;}
.profilebox{width:30%;}
.contentbox{width:70%;}
.careersummary li{width: 25%;}
.careersummary li:nth-child(5):after{display: block;}
.careersummary li:nth-child(4):after, .careersummary li:nth-child(8):after{display: none;}

}
@media (max-width:991px){
.topfooter h4{font-size: 16px;}
.topfooter ul li{font-size: 13px;}
.about-footer{padding-right: 25px;}	
.about-footer p{font-size: 12px; line-height: 20px;}
.copyyright{font-size: 14px;}

.left-dashboard ul li a figure {margin-right: 10px;}
.game-heading{margin: 0 0 10px;}
.game-heading h1{font-size: 18px;}
.game-heading > a{font-size: 14px;}	
.edit-team{padding: 0 15px;}
.gamestack ul li{width: 33.33%; padding: 15px 10px 15px;}

.gamestack{margin: 0 0 15px;}	
.quickrow .right-block{padding: 0 0 15px 0;}	
.block-section{margin: 0 0 15px;}	
.block-section .col-6 {width: 100%; padding-bottom: 15px; flex: 0 0 100%; max-width: 100%;}
.block-section .col-6:last-child{padding-bottom: 0;}	
.right-block{padding: 15px 0 0 0;}
.playbox ul li{width: 100%; padding: 0 15px 30px;}
.playbox li::after{height: 1px; top: auto; bottom: 15px; margin: auto; right: 0; width: 100%;}

.playercardlist ul li{width: 33.33%;}
.article_list{margin: 0 -15px;}
.article_list >  li{width: 50%; padding: 0 15px 30px;}
.tradetable.table thead tr th:first-child, .tradetable.table tbody tr td:first-child{width: 60%;}

.gamemember.playercardlist ul li{width: 50%;}
.sponsor_list > li{width: 50%;}

.prize-section ul li{padding: 0 10px 20px;}
.prizebox figure img{max-height: 120px;}
.prizebox h4{font-size: 18px; line-height: 20px; margin: 0 0 20px;}
.prizebox span{font-size: 13px; line-height: 20px; padding: 0 0 10px 15px;}


.profilebox{width:35%;}
.contentbox{width:65%;}
.datefilter ul li .form-control{font-size: 12px;}
.careersummary li{width: 33.33%;}
.careersummary li:nth-child(4):after, .careersummary li:nth-child(8):after{display: block;}
.careersummary li:nth-child(3):after, .careersummary li:nth-child(6):after, .careersummary li:nth-child(9):after{display: none;}
}
@media (max-width:767px){
body {padding-bottom: 54px;}	
.container{max-width:100%;}	
.logo img{max-width: 150px;}	
.container-fluid {padding-left: 15px; padding-right: 15px;}
.middle-section .container {padding: 0px 5px;}
.right-dashboard {padding: 10px 5px 20px 5px;}	
.game-heading {align-items: center; padding: 0 0 0 40px;}	
.gameinfo ul li {width: 50%;}
.gameinfo ul li.customli {width: 33%;}
.gamestack ul li{width: 50%; padding:10px 10px 10px;}	
.boxheading .border-btn{height: 44px; padding: 8px 12px; font-size: 16px; line-height: 26px;}
.matchbar{align-items: flex-start; flex-direction: column; padding: 10px 0 18px 15px;}	
.matchinfo{padding:5px 0 0 60px;}
.edit-team{padding: 15px 0 0 0;}	
.gameprofile.open .whitebox{height: auto;}
.findgame-section {margin: 0 0 5px;}
.findgamebox .form-control{font-size: 14px;}
.findgamebox .searchimg {height: 20px; width: 20px;}	
.left-dashboard {z-index: 21; height: calc(100% - 56px);}	
.left-dashboard ul li a span{font-size: 14px; line-height: 18px;}	
.left-dashboard ul li a{padding: 5px 10px 5px 10px; min-height: 48px;}

.block-section .col-6 {padding-right: 5px; padding-left: 5px;}	
	
footer .container-fluid {padding: 0 15px;}
.topfooter {padding: 20px 0 0 0; flex-wrap: wrap;}
.about-footer{padding-right: 0; width: 100%;}
.about-footer p{margin-bottom: 5px;}	
.about-footer figure{margin: 0 0 10px;}
.about-footer figure img{max-height: 28px;}
.link, .about, .get-started, .more{width: 100%; margin-bottom: 15px;}
.topfooter ul{padding: 10px 0 0;}
.topfooter h4{display: none;}
.topfooter h4.mobileheading{display: block; margin: 0; border-bottom: 1px solid rgba(215,215,215,0.25); font-size: 14px; cursor: pointer; background: url("../images/dropdown-arrow.png") no-repeat center right;}
footer::after {background-attachment: fixed;}	
.copyyright{padding:0 0 10px 0px; border: none; font-size: 13px;}
.dashboard-section.open .left-dashboard {width: 250px;}
.gamebar-btn{width: 30px; height: 30px; padding: 8px;}
	
.gamestack ul li strong{font-size: 18px; line-height: 22px;}
.gamestack ul li span{font-size: 13px; line-height: 18px;}	
.sponsorslide p{font-size: 14px;}

.quicklink ul li{font-size: 16px; line-height: 38px;}
.gameinfo ul li{padding: 10px 10px 10px;}	
.gameinfo ul li strong{font-size: 18px; line-height: 22px;}
.gameinfo ul li span{font-size: 13px; line-height: 18px;}

.matchinfo strong {font-size: 16px; line-height: 24px;}
.matchinfo span {font-size: 12px; line-height: 16px;}	
.edit-team a{font-size: 12px; line-height: 20px;}
.edit-team a img{margin: 0 5px 0 0; max-width: 18px;}	

.gamevideo > figure > img{height: 100px;}
.user-profile{width: 75px; height: 75px; margin: -38px auto 15px; padding: 5px;}
.profile-content h4{font-size: 20px; line-height: 28px; margin: 0 0 10px;}
/* .profile-content > p {font-size: 14px; line-height: 20px;} */
.promocode .form-control {height: 40px; font-size: 14px;}
.promocode .border-btn {padding: 5px 15px; height: 40px; font-size: 14px;}
.social-link{padding: 5px 0 10px;}
.social-link li{padding: 0 5px;}
.bottom-link li a{font-size: 16px; line-height: 24px; padding: 10px 0;}

.admindetail{font-size: 14px;}	
.playercardlist ul{margin: 0 -5px;}
.playercardlist ul li{width: 50%; padding:40px 5px 20px;}	
.playercardbox{padding: 55px 5px 15px 5px;}	
.playercardimg figure {top: -40px; height: 80px; width: 80px;}
.playercardimg figure img{max-height: 75px;}	

.playercardbox h4{font-size: 14px; margin: 0;}	
.playercardbox h6{font-size: 12px; line-height: 18px;}
.playercardbox span{font-size: 12px;}	

	
.gamepass-detail{padding: 20px 15px;}	
.gamepass-detail h2{font-size: 20px; line-height: 24px;}
.gamepass-detail p{font-size: 14px; line-height: 22px;}	
.gamepass-detail .btn-primary{font-size: 14px; line-height: 22px; margin-top:0;}	
.modal-header .btn-close{height: 16px; width: 16px;}
.modal-body{padding: 0 5px;}
.modal-body .table thead tr th, .modal-body .table tbody tr td{padding: 8px 10px; font-size: 12px;}

.ruleaccordion{padding: 20px 10px;}	
.ruleaccordion .accordion-body{padding: 20px 15px;}	
.ruleaccordion .accordion-body p{font-size:14px;}	
.ruleaccordion .accordion-button{font-size: 14px;}

.sponsor_list > li{width: 100%; padding: 0 0px 20px;}	
	
.article_list{margin: 0 -5px;}
.article_list >  li{padding: 0 5px 10px;}
.articlebox{padding: 10px 8px 15px;}
.tradetable.table thead tr th, .tradetable.table tbody tr td{padding: 8px 8px;}
.tradetable.table thead tr th:first-child, .tradetable.table tbody tr td:first-child{width: 50%;}	
.tradetable.table thead tr th, .tradetable.table tbody tr td{font-size: 12px;}

.gamemember.playercardlist ul li{width: 100%;}	
.gamemember .playercardbox{padding: 10px 0px 5px 100px;}
.tradetab.nav-tabs .nav-link {padding: 6px 10px; font-size: 12px;}

.winnerrable.table thead tr th:nth-child(1), .winnerrable.table tbody tr td:nth-child(1){padding: 8px 0 8px 8px;}	
.winnerrable.table thead tr th, .winnerrable.table tbody tr td{padding: 8px 8px; font-size: 12px;}
.fixturerable.table thead tr th, .fixturerable.table tbody tr td{padding: 8px 8px; font-size: 12px;}

.gamestatstable.table thead tr th{font-size: 12px;}	
.gamestatstable.table thead tr th:first-child, .gamestatstable.table tbody tr td:first-child{width: 45%; padding: 8px 8px;}

.prize-section ul{margin: 10px 0 0;}
.prize-section ul li{width: 100%; padding: 0 0 30px;}
.prizebox{padding: 20px 18px 20px;}	
.searchfilter .custom-searchbox{right: 45px; top: 9px;}
.searchfilter .custom-searchbox img{max-width: 20px;}	
#pointsystem .modal-body .searchfilter{padding-right: 15px; padding-left: 15px;}
.modal-body .searchfilter .dropdown{right: 0; top: 10px;}

.gamestatstable .playerinfo{padding: 3px 0px 3px 55px;}	
.modal-body .searchfilter .custom-searchbox{right: 30px;}

.profile-section{padding: 10px 5px;}
.profile-section .container{padding: 0;}	
.profilebox{width:100%;}
.contentbox{width:100%; padding:20px 0;}
.contentbox h4{font-size: 18px;}	
.datefilter ul{margin: 0; flex-wrap: wrap; padding: 15px 0px;}

.careersummary{margin:10px 0;}	
.careersummary li{width: 100%; margin: 0px 0 10px 0;}
.careersummary li:after{display: none !important;}
.summarybox{width: 100%; display: flex; align-items: center;}
.summarybox figure{width: 30px; height: 30px; margin: 0 15px 0 0;}
.summarybox figure img{max-width: 16px;}	
.summarybox strong{position: absolute; right: 5px; top: 6px; padding: 0; font-size: 14px;}
	
#claimplayer .modal-body{padding: 20px 10px;}
#viewmodal .modal-body .table thead tr th, #viewmodal .modal-body .table tbody tr td, #viewmodal .table tr td > strong{font-size: 12px;}
#pointsystem .modal-body, #datemodal .modal-body{max-height: 450px;}
.selectdropdown.teamsort{width: 95px; padding: 6px 8px; font-size: 13px;}
.selectdropdown.gameweeksort{width:145px; padding: 6px 8px; font-size: 13px;}
.innerwhite-section .tab-content.scorecardtabcontent{margin-top: -15px;}

.innerwhitebox{padding: 10px 10px 10px 10px;}	
.tradetab.nav-tabs .nav-item{padding: 0 3px 10px;}


.modal-header{padding: 10px 10px;}
}
@media (max-width:567px){
.article_list{margin: 0 0;}
.article_list >  li{width: 100%; padding: 0 0 20px;}
}



/*.clone.table tbody tr td:not(:first-child){display: none;}*/
.statdropdown{border: none; background-color: transparent; color: #ffffff; text-align: center;}
.statdropdown option{color: #000000;}
.statstable .table-scroll tbody th, .statstable .table-scroll tbody td{height: 79px;}
.statstable .table-scroll th:first-child{border-radius:0 0 0 0;}

.selectdropdown{border: 1px solid #0A0F2E; border-radius: 5px; background-color: #ffffff; padding: 6px 12px; margin-top: -10px;}
.selectdropdown.gameweeksort{margin-right: 3px;}

.table-scroll{position:relative; margin:auto; overflow:hidden;}
.table thead tr th.fixed-side{background-color: #0A0F2E; color: #ffffff;}
.table-wrap{width:100%; overflow:auto;}
.table-scroll table{margin:auto;}
.table-scroll th, .table-scroll td{padding:5px 10px; white-space:nowrap; vertical-align:middle; background-color: #f4f3f6; border: none;}
.clone{position:absolute; top:0; left:0; pointer-events:none;}
.clone th, .clone td{visibility:hidden;}
.clone td, .clone th{border:none;}
.clone tbody th{visibility:visible;}
.clone .fixed-side{background:#f4f3f6; visibility:visible; border:none; border-bottom: 1px solid #000000;}
.clone th.fixed-side{border-bottom: 6px solid #ffffff; border-radius:0 0 0 0;}
.innertable.clone .fixed-side{font-weight: normal; font-size: 14px; padding: 8px 8px;}
.table-scroll .innertable th.fixed-side{text-align: center; border-radius:0 0 0 0;}
.table-scroll .innertable th.fixed-side:first-child{text-align: center;}
.table-scroll .innertable th.fixed-side a{color: #212529;}
.table-scroll .innertable td a{color: #212529;}
.tabledata .customscroll{padding:10px;}
/*.tabledata .table tbody tr td {border-bottom: 8px solid transparent !important; border-top: 8px solid transparent !important;}*/
.tabledata .table tbody tr td:first-child{border-radius:0px 0 0 0px; padding: 5px 10px;}
.tabledata .table tbody tr td:last-child{border-radius: 0 0px 0px 0;}

.contentbox .table.main-table1 thead tr th:first-child, .contentbox .table.main-table1 tbody tr td:first-child, .contentbox .table.main-table2 thead tr th:first-child, .contentbox .table.main-table2 tbody tr td:first-child, .contentbox .table.main-table3 thead tr th:first-child, .contentbox .table.main-table3 tbody tr td:first-child, .contentbox .table.main-table4 thead tr th:first-child, .contentbox .table.main-table4 tbody tr td:first-child{text-align: center;}

@media (max-width:767px){
.tabledata .customscroll{padding: 10px 5px;}
.playerinfo{min-height: 50px;}
.statstable .table-scroll tbody th, .statstable .table-scroll tbody td{height: 66px;}

.table-scroll .innertable th.fixed-side, .table-scroll .gamestatstable th.fixed-side{opacity: 0; visibility: hidden;}
.table-scroll .innertable.clone th.fixed-side, .table-scroll .gamestatstable.clone th.fixed-side{opacity: 1; visibility: visible;}
.table-scroll .innertable th.fixed-side {text-align: left; min-width: 136px;}
.contentbox .table.main-table1 thead tr th:first-child, .contentbox .table.main-table1 tbody tr td:first-child, .contentbox .table.main-table2 thead tr th:first-child, .contentbox .table.main-table2 tbody tr td:first-child, .contentbox .table.main-table3 thead tr th:first-child, .contentbox .table.main-table3 tbody tr td:first-child, .contentbox .table.main-table4 thead tr th:first-child, .contentbox .table.main-table4 tbody tr td:first-child{text-align: left;}
}


.pagetitle{text-align: center; margin: 0 0 20px;}
.pagetitle h1{font-size: 40px; font-weight: 600; margin: 0; color: #ffffff; text-transform: uppercase;}

.formsection .container-fluid{display: flex; padding: 0; min-height: 62vh;}
.heading h2{font-weight: 600; font-size: 26px; line-height: 39px; color: #000000; margin: 0;}
.leftform{width: 55%; background-image: url("../images/bannerbg.jpg"); background-repeat: no-repeat; background-size: cover; padding: 50px 20px; display: flex; align-items: center; justify-content: center;}
.leftform .heading h2{color: #ffffff; text-align: center;}
.rightform{width: 45%; padding: 50px 80px; display: flex; align-items: center;}
.styled-selectors{position:relative;}
.styled-selectors input[type=checkbox]:not(old), .styled-selectors input[type=radio]:not(old):not(.style_none_checkbox){width:28px; margin:0; padding:0; opacity:0; position:absolute; left:-1px; cursor:pointer;}
.styled-selectors input[type=checkbox]:not(old)+label, .styled-selectors input[type=radio]:not(old)+label{display:inline-block; padding-left:30px; line-height:28px; cursor:pointer;}
.styled-selectors input[type="checkbox"]:not(old) + label{background:url(../images/uncheck.png) no-repeat; background-position:0px 2px;}
.styled-selectors input[type="checkbox"]:checked + label{background:url(../images/check.png) no-repeat; background-position:0px 2px;}
.styled-selectors input[type="radio"]:not(old) + label{background:url(../images/radio-uncheck.png) no-repeat; background-position:0px 4px;}
.styled-selectors input[type="radio"]:checked + label{background:url(../images/radio-checked.png) no-repeat; background-position:0px 4px;}

.heading {margin: 0 0 30px;}
.heading h2{font-weight: 600; font-size: 26px; line-height: 39px; color: #000000;}
.button-group{text-align: center;}
.button-group .btn{margin: 0 5px;}

.loginform{width: 550px; margin: auto; max-width: 100%;}
.loginform .form-group{margin: 0 0 25px;}
.loginform .form-group.forgotpass{display: flex; align-items: center; justify-content: space-between;}
.password-input{position: relative;}
.password-input .visibility-icon{position: absolute; right: 10px; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;}
.loginform .form-control{border: 1px solid #0A0F2E; border-radius:5px; height: 55px;}
.loginform .form-control:focus{border: 1px solid #0A0F2E;box-shadow: none;}
.forgotpass a.forgot_link{color: #000000; text-decoration: none;}
.loginform .form-group .btn-primary{width: 100%;}

.signupform .loginform {width: 100%;}
.form_sec_heading{font-weight: 600; font-size: 18px; line-height: 24px; color: #000000; margin: 0 0 10px;}
.already_logon_text{text-align: center;}
.already_logon_text a{text-decoration: none; color: #1877F2;}
.erro_msg {position: relative; margin-top: -20px; font-size: 12px;}
.customfiletype.form-control{padding: 0; position: relative;}
.customfiletype.form-control > input{position: absolute; right: 0; top: 0; bottom: 0; width: 170px; padding: 10px 10px; cursor: pointer;}
.customfiletype.form-control > a {position: absolute; right: 0; top: 0; bottom: 0; background-color: #0A0F2E; color: #ffffff; text-decoration: none; padding: 15px 30px; text-transform: capitalize; pointer-events: none;}

.bannertitle{text-align: center; min-height: 350px; display: flex; align-items: center; justify-content: center; flex-direction: column;}
.bannertitle h1{font-size: 40px; font-weight: 600; margin: 0; color: #ffffff; text-transform: uppercase;}
.bannertitle p{font-size: 16px; font-weight: 300; color: #ffffff;}
.bannertitle p a{color: #ffffff; text-decoration: underline;}
.club_img {width: 60px; height: 60px; border-radius: 100%; border: 1px solid #0f70b7; overflow: hidden;}

.user-all-games.table thead tr th{padding: 12px 15px; text-align: left;}
.user-all-games.table tbody tr td{padding: 8px 15px; text-align: left;}
.club_name{font-size: 18px; font-weight: 600;}
.customanch{width: 90px; font-size: 12px; padding: 8px 0px; text-align: center; border-radius: 5px;}
.badge-success{color: #ffffff; background-color: #28a745;}
.badge-success:hover{color: #ffffff; background-color: #1e7e34;}
.badge-warning{color: #212529; background-color: #ffc107;}
.badge-warning:hover{color: #212529; background-color: #d39e00;}
.badge-primary{color: #ffffff; background-color: #007bff;}
.badge-primary:hover{color: #ffffff; background-color: #0062cc;}
.badge-danger{color: #ffffff; background-color: #dc3545;}
.badge-danger:hover{color: #ffffff; background-color: #bb2d3b;}
.user-all-games.table tbody tr td:last-child{width: 310px; max-width: 100%;}

.paginationlink{padding: 20px 0;}
.paginationlink .pagination{justify-content: center;}
.paginationlink .pagination .page-link{color: #0A0F2E; border: 1px solid #0A0F2E;}
.paginationlink .pagination .page-link:hover{background-color: #0A0F2E; color: #ffffff;}
.paginationlink .pagination .page-link:focus{box-shadow: none;}
.paginationlink .pagination .page-item.active .page-link{background-color: #0A0F2E; color: #ffffff;}

.partnerlist.sponsor_list > li{width: 50%;}
.partnerlist .btn-primary{font-size: 15px; padding: 6px 18px;}
.partnerlist.sponsor_list .sponsorslide{height: 100%;}


.switch-sport{display: flex; align-items: center; justify-content: center; flex-wrap: wrap;}
.switch-sport li{width: 25%; text-align: center; padding: 20px 25px;}
.switch-sport li a{width:100%; text-decoration: none; padding: 30px 15px; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; border-radius: 10px; background-color: #ffffff; flex-direction: column;}
.switch-sport li a:hover{background-color: #0A0F2E;}
.switch-sport li figure{margin: 0 0 25px;}
.switch-sport li img{max-width: 100px;}
.switch-sport li h4{font-weight: 600; font-size: 18px; color: #000000; margin: 0;}
.switch-sport li a:hover h4{color:#ffffff;}

.mygametable.table thead tr th{padding: 8px 15px; text-align: left;}
.mygametable.table tbody tr td{padding: 8px 15px; text-align: left;}
.mygametable.table thead tr th:first-child, .mygametable.table tbody tr td:first-child{width: 150px;}
.deleteimg{width: 30px; height: 30px; border-radius: 6px; text-align: center; border: 1px solid #ff372b; display: flex; align-items: center; justify-content: center;}
.deleteimg img{width: 18px; height: 18px;}

.game-searchbar{max-width: 500px; margin: 20px auto 5px; position: relative;}
.game-searchbar .form-control{height: 44px;}
.game-searchbar .form-control:focus{box-shadow: none;}
.game-searchbar .btn-primary{padding: 6px 15px; font-size: 14px; line-height: 20px; margin: 0 0 0 10px; height: 34px; position: absolute; top: 5px; right: 5px;}

.table-scroll .innertable td a.cancelbtn{text-transform: capitalize; background-color: #ff372b; color: #ffffff; font-size: 12px; padding: 5px 10px; text-align: center; border-radius: 5px;}

.creategamebtn{display: block; margin-top: 25px; padding: 30px 0 20px; border-top: 1px solid #ccc;}

.gamelist ul{display: flex; flex-wrap: wrap; align-items: flex-start; margin: 0 ;}
.gamelist ul li{width: 50%; padding:10px 10px;}
.gamebox{background: #FFFFFF; -webkit-box-shadow: 1px 1px 5px 3px rgba(0,0,0,.1); box-shadow: 1px 1px 5px 3px rgba(0,0,0,.1); border-radius: 10px; padding: 15px; position: relative;}
.gamebox h4{font-size: 16px; margin: 0; font-weight: 600;}
.gamebox h4 a{color:#212529;}
.gamebox h4 a:hover{color:#1877F2;}
.clubbox{padding: 0 50px 15px 0; position: relative;}
.clubbox span{font-size: 14px; color: rgb(98, 99, 115);}
.clubbox .club_img{width: 40px; height: 40px; position: absolute; top: 0px; right: 0;}
.gamebox .customanch{width:auto; padding: 6px 12px;}
.gamebox .styled-selectors{margin: 0; display: block;}
.gamebox .styled-selectors input[type="checkbox"]:not(old) + label, .gamebox .styled-selectors input[type="radio"]:not(old) + label{font-size: 14px; padding-left: 24px;}
.gamebox .styled-selectors input[type="checkbox"]:not(old) + label{background-position: 0px 4px; background-size: 18px;}
.gamebox .styled-selectors input[type="checkbox"]:checked + label{background-position: 0px 4px; background-size: 18px;}
.deletebtn{background-color: transparent; border: 1px solid #dc3545;  border-radius: 6px;position: absolute;right: 15px;bottom: 17px;padding: 0;height: 24px;width: 24px;display: flex;align-items: center;justify-content: center;}
.deletebtn img{width: 15px;}


.cms-section{padding: 50px 0;}
.cms-section .whitebox{padding:30px 25px;}
.cms-section p{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444; margin: 0 0 15px;}
.cms-section p strong{font-weight: 700;}
.cms-section p a{color:#1877F2;}

.blogsection{display: flex; flex-wrap: wrap; padding: 10px 10px;}
.left-blog{width: 70%;}
.right-blog{width: 30%; padding: 0 0 0 25px;}
.bloglist ul{display: flex; flex-wrap: wrap; margin: 0 -10px;}
.bloglist > ul > li{display: flex; width: 50%; padding: 0 10px 20px;}
.blogbox{border: 1px solid #ddd;}
.noborder{border:none;}
.blogbox figure{margin: 0 0 15px; height:215px; overflow: hidden;}
.blogbox figure a{display: block;}
.blogbox figure img{object-fit: cover; height:215px; aspect-ratio:1.9; width: 100%;}
.blogcontent{padding: 0px 15px 15px 15px;}
.blogcontent .date{margin: 0 0 10px; position: relative;}
.blogcontent .date span{display: inline-block; padding: 0 15px 0 0; color: #444444; font-size: 14px;}
.blogcontent .date .sponsor-social{position: absolute; top: 2px; right: 10px;}
.blogcontent h3{font-weight: 500; font-size: 20px; margin: 0 0 10px;}
.blogcontent h3 a{color: #1877F2;}
.blogcontent p{font-weight: 400; font-size: 14px; line-height: 22px; color: #444444; margin: 0 0 10px;}
.read-more a{text-decoration: none; font-weight: 400; font-size: 14px; line-height: 22px; color: #1877F2;}
.searchbox{position: relative; margin: 0 0 25px;}
.searchbox .form-control{border: 1px solid #0A0F2E; border-radius: 5px; height: 40px; font-size: 14px;}
.searchbox .form-control:focus{border: 1px solid #0A0F2E; box-shadow: none;}
.searchbox .submit-btn{border: none; background-color: transparent; position: absolute; top: 7px; right: 5px;}
.searchbox .submit-btn img{max-width: 20px;}
.categorybox{position: relative; margin: 0 0 25px;}
.categorybox .form-control{border: 1px solid #0A0F2E; border-radius: 5px; height: 40px; font-size: 14px;}
.categorybox .form-control:focus{border: 1px solid #0A0F2E; box-shadow: none;}
.categorybox:after{position: absolute; content: ""; right: 10px; top: 4px; bottom: 0; margin: auto; width: 16px; height: 9px; background: url("../images/bottom-arrow-blue.png") no-repeat; background-size: 12px; pointer-events: none;}

.right-blog h3{font-weight: 500; font-size: 20px; margin: 0 0 10px;}
.related-blog{margin: 0 0 25px;}
.related-blog ul li{padding: 5px 0; -ms-transition: all 0.8s ease 0s; -moz-transition: all 0.8s ease 0s; -o-transition: all 0.8s ease 0s; -webkit-transition: all 0.8s ease 0s; transition: all 0.8s ease 0s;}
.related-blog ul li a{font-weight: 400; font-size: 14px; line-height: 22px; color: #444444; -ms-transition: all 0.8s ease 0s; -moz-transition: all 0.8s ease 0s; -o-transition: all 0.8s ease 0s; -webkit-transition: all 0.8s ease 0s; transition: all 0.8s ease 0s;}
.related-blog ul li:hover{padding-left: 10px;}
.related-blog ul li:hover a{color: #1877F2;}

.pressrelease .left-blog{width: 100%;}
.pressrelease .bloglist > ul > li{width: 33.33%; padding: 0 10px 20px;}

.scorecardhead > span{display: none;}

.backbtn{padding: 0 10px;}
.backbtn .btn-primary{font-size: 14px; padding: 4px 12px;}
.backbtn .btn-primary i{display: inline-block; margin-right: 5px;}


.middle-content{padding: 50px 0;}
.left-content{text-align:right; display: flex; align-items:center; flex-direction: row-reverse; margin: 50px 0;}
.left-content figure img{max-width: 650px;}
.left-content .content-section{background-color: #ffffff; width: 50%; min-height: 250px; color: #000000; text-align: left; padding: 30px; margin:0 -100px 0 0; border-radius: 10px; -webkit-box-shadow: 0 0 10px 5px rgba(0,0,0,.05); box-shadow: 0 0 10px 5px rgba(0,0,0,.05); display: flex; flex-direction: column; justify-content: center;}
.right-content{text-align:right; display: flex; align-items:center; margin: 50px 0;}
.right-content figure img{max-width: 650px;}
.right-content .content-section{background-color: #ffffff; width: 50%; min-height: 250px; color: #000000; text-align: left; padding: 30px; margin:0 0 0 -100px; border-radius: 10px; -webkit-box-shadow: 0 0 10px 5px rgba(0,0,0,.05); box-shadow: 0 0 10px 5px rgba(0,0,0,.05); display: flex; flex-direction: column; justify-content: center;}
.content-section h2{font-size: 30px; font-weight: 500; margin: 0 0 15px;}
.content-section p{font-size: 16px; font-weight: 300; margin: 0 0 15px;}


.aboutus-content{padding: 0 0 30px;}
.aboutus-content h2{font-weight: 600; font-size: 36px; line-height: 30px; color: #000000; margin: 0 0 30px; text-align: center;}
.aboutus-content figure{float: left; margin: 0 30px 20px 0;}
.aboutus-content p{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444; margin: 0 0 15px;}

.vision-section{margin: 0 0 20px;}
.vision-section h2{font-weight: 600; font-size: 26px; line-height: 30px; color: #000000; margin: 0 0 10px;}
.vision-section p{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444; margin: 0 0 15px;}
.vision-section p:last-child{margin:0;}

.ourvision-section{display:flex; flex-wrap:wrap; margin:64px 0 0;}
.visionbox{width:50%; background-color:#f8f8f8; display:flex; flex-wrap:wrap; align-items:center; height:100%;}
.visionbox figure{width:50%; overflow:hidden;}
.visionbox figure img{-moz-transform:scale(1); -ms-transform:scale(1); -webkit-transform:scale(1); transform:scale(1); transition: all 0.5s ease 0s; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s;}
.visionbox:hover figure img{-moz-transform:scale(1.1); -ms-transform:scale(1.1); -webkit-transform:scale(1.1); transform:scale(1.1);}
.visionbox .visioncontent{width:50%; padding:15px 37px;}
.visionbox .visioncontent h4{font-size:24px; text-transform:uppercase; color:#000000; font-weight:700; margin:0 0 5px;}
.visionbox .visioncontent p{font-size:16px; color:#444444; margin:0;}

.our-team{margin: 70px 0 50px 0;}
.our-team h2{font-weight: 600; font-size: 26px; line-height: 33px; color: #000000; margin: 0 0 25px; text-align: center;}
.our-team > ul{display: flex; justify-content: center; align-content: center; text-align: center;}
.our-team > ul > li{padding: 0 15px 15px;}
.teambox{position: relative; margin:0 10px; border-radius: 6px; overflow: hidden;}
.teambox:after{position: absolute; content: ""; left: 0; bottom: 0; right: 0; height: 100%; width: 100%; background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );}
.teamcontent{position: absolute;  z-index: 1; bottom: 0; left: 0; right: 0; padding: 20px;}
.teambox figure{height: 350px; overflow: hidden; margin:0;}
.teambox figure img{opacity: 1; height: 350px; object-fit: cover; width: 100%;}
.teambox .sponsor-social{justify-content: center;}
.teambox .sponsor-social li a{background: #ffffff; color: #000000;}
.teambox .sponsor-social li a:hover{background: #0A0F2E; color: #ffffff;}
.teambox h4{font-weight: 600; font-size: 20px; line-height: 24px; color: #ffffff; margin: 0 0 5px; text-align: center;}
.teambox span{display: block; font-weight: 400; text-align: center; font-size: 16px; line-height: 24px; color: #ffffff; margin: 0 0 10px;}


.club_block{padding: 30px 0 30px;}
.club_block h3{font-weight: 600; font-size: 26px; line-height: 30px; color: #000000; margin: 0 0 30px; text-align: center;}
.clublogo figure{text-align: center; border: 1px solid #ddd; margin: 0px 10px; padding: 20px 20px; border-radius: 10px;}
.clublogo figure img{max-height: 150px; margin: auto;}
.clubslider .slick-slide img{opacity: 1;}
.clubslider .slick-prev{position: absolute; top: 50%; left: -15px; margin-left: 0; z-index: 3; width: 40px; height: 40px; border-radius: 50%; background: #FFFFFF url("../images/left-arrow.png") no-repeat center; border: 1px solid rgb(230, 233, 236);} 
.clubslider .slick-next{position: absolute; top: 50%; right: -15px; margin-right: 0; z-index: 3; width: 40px; height: 40px; border-radius: 50%; background: #FFFFFF url("../images/right-arrow.png") no-repeat center; border: 1px solid rgb(230, 233, 236);}
.clubslider .slick-prev:before, .clubslider .slick-next:before{display: none;}

.about_block{padding: 30px 0;}
.about_block h3{font-weight: 600; font-size: 26px; line-height: 30px; color: #000000; margin: 0 0 30px; text-align: center;}
.testimonial .card{border-style: solid; border-width: 1px solid; border-color: rgb(230, 233, 236); border-radius: 5px; padding: 20px 27px 20px 22px;  margin: 0 10px;}
.testimonial .card .image_box {display: flex; align-items: center;}
.testimonial .card .image_box figure{max-width: 100px; flex: 0 0 100px; border-radius: 50%; overflow: hidden; height: 100px; display: flex; align-items: center; justify-content: center;}
.testimonial .slick-slide img{opacity: 1;}
.testimonial .card .image_box .images_box_content {margin-left: 19px;}
.testimonial .card .image_box .images_box_content h4{display: block; font-size: 18px; font-weight: 600; margin:0 0 5px;}
.testimonial .card .image_box .images_box_content strong{display: block; font-size: 16px; line-height: 18px; font-weight: 500; margin: 0 0 5px;}
.testimonial .card p{margin-top: 20px; font-size: 15px; margin-bottom: 0;}
.testimonial .slick-prev{position: absolute; top: 50%; left: -5px; margin-left: 0; z-index: 3; width: 40px; height: 40px; border-radius: 50%; background: #FFFFFF url("../images/left-arrow.png") no-repeat center; border: 1px solid rgb(230, 233, 236);} 
.testimonial .slick-next{position: absolute; top: 50%; right: -5px; margin-right: 0; z-index: 3; width: 40px; height: 40px; border-radius: 50%; background: #FFFFFF url("../images/right-arrow.png") no-repeat center; border: 1px solid rgb(230, 233, 236);}
.testimonial .slick-prev:before, .testimonial .slick-next:before{display: none;}

@media (max-width:1199px){
.rightform{padding: 30px 25px;}
.signupform .styled-selectors input[type="checkbox"]:not(old) + label, .signupform .styled-selectors input[type="radio"]:not(old) + label{font-size: 14px;}
.signupform .leftform{width: 50%;}	
.signupform .rightform{width: 50%;}	
.loginform .form-control {font-size: 14px;}

.switch-sport li{padding: 20px 15px;}
	
.blogbox figure{height:170px;}
.blogbox figure img{height:170px;}

.visionbox .visioncontent{padding: 10px 20px;}
}
@media (max-width:991px){
.loginform .form-control {height: 44px;}
.forgotpass a.forgot_link{font-size: 14px;}
.styled-selectors input[type="checkbox"]:not(old) + label, .styled-selectors input[type="radio"]:not(old) + label{font-size: 14px;}

.formsection.signupform .container-fluid{flex-direction: column;}
.formsection.signupform .leftform{width: 100%;}
.formsection.signupform .rightform{width: 100%;}

.club_name{font-size: 16px;}
.partnerlist.sponsor_list > li{width: 100%;}

.switch-sport li{padding: 10px 10px;}
.switch-sport li a{padding: 20px 10px;}
.switch-sport li h4{font-size: 16px;}
.switch-sport li figure{margin: 0 0 20px;}	
.switch-sport li img{max-width: 80px;}

.gamelist ul li{width: 50%;}

.blogbox figure{height:123px;}
.blogbox figure img{height:123px;}	

.aboutus-content figure {float: none; margin: 0 0px 30px 0; text-align: center; display: none;}
.visionbox{width: 100%;}
.visionbox:last-child{flex-direction:row-reverse;}
.visionbox:last-child figure{text-align: right;}

.ourvision-section{margin: 20px 0 0;}	
.ourteam .slick-prev{position: absolute; top: 50%; left: -15px; margin-left: 0; z-index: 3; width: 40px; height: 40px; border-radius: 50%; background: #FFFFFF url("../images/left-arrow.png") no-repeat center; border: 1px solid rgb(230, 233, 236);} 
.ourteam .slick-next{position: absolute; top: 50%; right: -15px; margin-right: 0; z-index: 3; width: 40px; height: 40px; border-radius: 50%; background: #FFFFFF url("../images/right-arrow.png") no-repeat center; border: 1px solid rgb(230, 233, 236);}
.ourteam .slick-prev:before, .ourteam .slick-next:before{display: none;}
}
@media (max-width:767px){
.nopadding{padding:0;}	
.formsection .container-fluid {flex-direction: row-reverse; flex-wrap: wrap;}
.pagetitle{margin: 0 0 10px;}
.pagetitle h1{font-size: 30px;}
.leftform .heading h2{font-size: 20px; line-height: 24px;}	
.heading{margin: 0 0 20px;}
.leftform{width: 100%; padding: 30px 15px; min-height: 200px;}
.leftform .btn-secondary{font-size: 14px;}	
.rightform{padding: 20px 15px; width: 100%;}	
.signupform .styled-selectors input[type="checkbox"]:not(old) + label, .signupform .styled-selectors input[type="radio"]:not(old) + label{font-size: 13px;}

.loginform{width: 100%;}	
	
.bannertitle{min-height: 100px;}
.bannertitle h1{font-size: 24px;}

.user-all-games.table thead tr th:first-child{padding-left:15px;}	
.user-all-games.table thead tr th{padding: 12px 8px;}	
.user-all-games.table tbody tr td{padding: 8px 8px;}
.user-all-games.table tbody tr td:last-child{width: auto;}
.club_img{width: 40px; height: 40px;}
.club_name{font-size: 14px;}
.club_detail{display: flex; align-items: center; justify-content: flex-end;}
.customanch{font-size: 10px; padding: 5px 5px; margin: 0 0 0 2px; width: auto;}

.paginationlink .pagination .page-link {padding: 2px 10px; font-size: 14px;}

.before-login .navbar-expand-lg .navbar-toggler{display: block;}
.before-login .navbar-expand-lg .navbar-collapse{display: flex !important; position: fixed; height: calc(100% - 46px); width: 100%; right: -100%; top: 46px; bottom: 0;  background-color: #0A0F2E; z-index: 12; overflow: auto; padding: 0 0 20px; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.before-login .navbar-expand-lg .navbar-collapse.show{right: 0; align-items: flex-start;}
.before-login .header .navbar-expand-lg .navbar-nav{flex-direction: column;}
.before-login .header .navbar-expand-lg .nav-item{margin: 0; width: 100%; padding: 20px 0px 0px 20px;}

.recentperformance{margin: 15px 0 0;}	
.matchscorecard .innerwhite-section{position: relative;}	
.viewcard{display: block; position: absolute; bottom: -4px; right: 0; color: #000000; background-color: #FFD43B; font-size: 12px; padding: 3px 8px; border-radius: 6px; font-weight: 500;}
	
.switch-sport li{width: 50%;}
.switch-sport li a{padding: 10px 10px;}	
.switch-sport li figure{margin: 0 0 10px;}	
.switch-sport li img{max-width: 60px;}
.mygametable.table thead tr th:first-child, .mygametable.table tbody tr td:first-child{width: auto;}
.mygametable.table tbody tr td{padding: 8px 8px;}
.mygametable.table thead tr th:first-child{font-size:0;}
.game-searchbar{margin: 20px 10px 5px;}
.customfiletype.form-control > a{padding: 10px 30px;}	
.customfiletype.form-control > input{width: 140px; padding: 10px 10px;}
.button-group .btn.btn-primary{font-size: 16px;}

#viewmodal .modal-body .table thead tr th, #viewmodal .modal-body .table tbody tr td{padding: 5px 10px;}	
	
.gamelist ul li{width: 100%;}
.gamebox .club_detail{display: block;}	
.gamebox .club_detail .customanch{font-size: 12px; padding: 8px 0px; width: 98px;}

.cms-section .whitebox{padding: 30px 15px;}	
.cms-section p {font-size: 14px; line-height: 22px; margin: 0 0 10px;}


.blogsection{flex-direction: column-reverse;}
.left-blog{width: 100%;}
.right-blog{width: 100%; padding: 0 0 25px 0;}
.categorybox{margin: 0;}	
.related-blog{display: none;}
.bloglist ul{margin: 0;}
.bloglist > ul > li {width: 100%; padding: 0 0 20px;}
.blogbox figure{height: 190px;}	
.blogbox figure img{height: auto; aspect-ratio:16/9;}
.shareon{margin: 25px 0 0;}
.pressrelease .bloglist > ul > li{width: 100%; padding: 0 0 20px;}
.blogcontent .date .sponsor-social{right:0;}	

.scorecardhead .venuetitle{font-size: 14px; margin: 0;}
.scorecardhead{position: relative; margin-bottom: 0;}
.scorecardhead > span{display: block; font-size: 14px; text-align: center; font-weight: 500; margin: 0 0 10px;}
.scorecardhead > span strong{display: none;}
.summaryhead{position: relative; margin-bottom: 20px;}
.summaryhead .viewcard{bottom: 4px;}	
	
.vision-section h2{font-size: 22px;}	
.our-team h2{font-size: 22px;}	
.our-team{margin: 30px 0 30px 0; padding: 20px 10px 30px;}	
.club_block h3{font-size: 22px;}	
.teambox{margin: 0;}	
.teambox h4 {font-size: 16px; line-height: 18px;}
.teambox span{font-size: 14px; line-height: 20px;}
.clublogo figure{padding: 10px 10px; margin: 0px 5px;}
.clublogo figure img{max-height: 100px;}

.testimonial .card{padding: 15px 12px 15px 12px; margin: 0;}
.testimonial .card .image_box figure{max-width: 80px; flex: 0 0 80px; height: 80px;}
.testimonial .card .image_box .images_box_content strong{font-size: 14px; line-height: 18px;}
.testimonial .card p{font-size: 14px;}
.testimonial .slick-prev{left: -12px; margin-top: -12px; width: 32px; height: 32px; background-size: 20px;}
.testimonial .slick-next{right: -12px; margin-top: -12px; width: 32px; height: 32px; background-size: 20px;}
.clubslider .slick-prev{left: -12px; width: 32px; height: 32px; background-size: 20px;}
.clubslider .slick-next{right: -12px; width: 32px; height: 32px; background-size: 20px;}	

.featuretabcontent .introduction-section ul{margin: 15px 0 0;}

.aboutus-content h2 {font-size: 26px; line-height: 20px; margin: 0 0 20px;}
.visionbox .visioncontent{padding: 10px;}
.visionbox .visioncontent h4{font-size: 18px;}	
.visionbox .visioncontent p{font-size: 13px; line-height: 18px;}
}





.home-banner{background-image: url("../images/bannerbg.jpg"); background-repeat: no-repeat; background-size: cover; overflow: hidden; position:  relative; z-index: 1;display: flex; align-items: center; justify-content: center; margin: auto; padding: 80px 0;}
.home-banner .banner-content{flex-direction: column; text-align: left; align-items: flex-start; justify-content: center; padding: 0;}
.home-banner .banner-caption{display: flex; align-items: center; justify-content: space-between; position: relative;}
.home-banner .banner-content h1{font-size: 60px;  line-height: 80px; text-transform:uppercase;  color: #ffffff;  font-weight: 700; margin: 0 0 15px;}
.home-banner .banner-content h2{font-size: 24px; color: #FEB740; margin-bottom: 48px; text-transform: capitalize;}
.home-banner .banner-content span{ font-size:28px; line-height:30px; color: #ffffff;  font-weight: 400; display: block; margin: 0 0 15px;}
.home-banner .banner-content{flex: 0 0 45%; max-width: 45%;}
.banner_btn{display:flex;}
.banner_btn .border-btn{padding: 11px 20px; height: auto;}
.banner_btn .border-btn:first-child{margin-right: 10px;}
.home-banner .banner_right{flex: 0 0 55%; max-width: 55%; text-align: right;}


.introduction_section{position: relative; padding: 50px 0; background: linear-gradient(236.28deg, #CA1744 4.07%, #0B1449 75.27%);}
.introduction_section .container{position: relative; z-index: 2;}
.introduction_section:after{position: absolute; content: ""; left: 0; top: 0; width: 100%; height: 100%; background: url("../images/texturebg.png") no-repeat;}
.introduction_box{display: flex; justify-content: center; align-items: center;}
.introduction_box .left{flex: 0 0 50%; max-width: 50%; z-index: 1;}
.introduction .slick-slide img{opacity: 1; margin: auto;}
.introduction .slick-prev.slick-arrow, .introduction .slick-next.slick-arrow{display: none !important;}
.introduction_box .left figure{text-align: center; padding: 0; position: relative;}
.introduction_box .right_content{flex: 0 0 50%; max-width: 50%;}
.introduction_box .right_content h3{font-size: 40px; font-weight: 700; color: #ffffff; position: relative; display: table;margin-bottom: 55px; margin-top: 32px;}
.introduction_box .right_content h3::after{content: '';position: absolute;left: 0;display: table;margin-top: 10px;width: 84px;height: 8px;background-color: #ffffff;;border-radius: 10px;}
.introduction_box .right_content p{font-size: 16px; font-weight:400; line-height: 24px; color: #ffffff; margin-bottom: 29px;}
.introduction_box .right_content ul li{position: relative; color: #ffffff; padding-left: 30px; padding-right: 0px;}
.introduction_box .right_content ul li::after{position: absolute;  content: '';  width: 14px; height: 14px; border-radius: 5px; left: 0px; top: 5px;
right: 0;  z-index: 1;  background-color: #ffffff;}
.introduction_box .right_content ul li h4{ font-size: 20px; font-weight: 700;  margin-bottom: 4px;}

.cms-section .heading h2{color: #ffffff;}
.app_user_section{padding: 20px 0 50px 0;}
.heading{text-align: center;}
.heading h2{font-size: 40px; font-weight: 700; margin: 0 auto; position: relative; color: #0A0F2E;}
.app_user_section ul {display: flex; justify-content: space-between; align-items: center; padding-left: 0;  padding-top: 40px; flex-wrap: wrap;} 
.app_user_section ul li{flex: 0 0 33.33%; max-width: 33.33%;}
.app_user_section ul li .teams_box{display: flex;}
.app_user_section ul li .teams_box .teams_right_top{display: flex;  flex-wrap: wrap; flex-direction: column;justify-content: space-between;}
.app_user_section ul li .teams_box .teams_content_box{margin-left: 21px; display: flex; align-items: center; padding-bottom: 0; padding-right: 30px; line-height: 23px;}
.app_user_section ul li .teams_content_box span{margin: 0; font-size: 20px; font-weight: 700; margin-left: 10px;}
.app_user_section ul li .teams_box .teams_bottom{background-color: #0A0F2E; color: #ffffff; border-radius: 5px; padding: 10px 11px 10px 40px;
font-size: 17px; max-width: 235px; margin-left: -20px; min-height: 88px;}
.app_user_section ul li .teams_box .teams_bottom p { margin: 0; font-size: 16px; line-height: 20px;}
.app_user_section ul li .teams_box .teams_images{flex:0 0 165px; max-width: 165px; position: relative; z-index: 3;}
.app_user_section ul li .teams_box .teams_box .teams_right_top{flex: 0 0 217px; max-width: 217px; }
.app_user_section ul li .teams_box .teams_content_box strong{color: #fdf0d6; font-size: 100px; line-height: 100px;}
.introduction-section .heading{text-align: center; padding: 20px 0;}
.introduction-section .heading h4{font-size: 24px; font-weight: 600; color: #0A0F2E;}
.features_section{padding: 50px 0 80px;}
.introduction-section ul{margin: 25px 0 0; display: flex; flex-wrap: wrap;}
.introduction-section ul li{padding: 0 10px 20px; width: 33.33%;}
.introductionbox{padding: 33px 16px 30px 30px; background-color: #ffffff; border: 1px solid #e5e4f5; height: 100%; border-radius: 5px;}
.introductionbox figure{margin: 0 0 25px; width: 87px; height: 87px; display: flex;}
.introductionbox h4{font-weight: 700; font-size: 18px; color: #0A0F2E; margin-bottom: 10px; text-transform: uppercase;}
.introductionbox p{color: #737789; font-size: 16px; line-height: 24px; margin: 0;}

.gamebar_detail h2{color: #ffffff; font-weight: 600; font-size: 30px; margin: 0 0 15px;}
.auditstep{display:flex; flex-wrap:wrap; align-content:center; justify-content:center; margin:30px 0 0;}
.auditstep li{padding:0px 80px; position:relative;}
.auditstep li:before{position:absolute; content:""; height:8px; width:120px; background-color:#edf0f5; left:-5px; top:15px;}
.auditstep li:after{position:absolute; content:""; height:8px; width:120px; background-color:#edf0f5; right:-5px; top:15px;}
.auditstep li:first-child:before{display:none;}
.auditstep li:last-child:after{display:none;}
.auditstep li .count{height:40px; width:40px; border-radius:50%; margin:auto; background-color:#ffffff; border:2px solid #edf0f5; display:flex; justify-content:center; align-content:center; position:relative; z-index:1;}
.auditstep li .count strong{height:26px; width:26px; border-radius:50%; margin:auto; background-color:#edf0f5; font-size:18px; font-weight:700; font-family: 'Poppins', sans-serif;}
.auditstep li .stepdetail{display:block; margin:10px 0 0; font-family: 'Poppins', sans-serif; font-size:16px; font-weight:500;}
.auditstep li a{color:rgba(48,62,103, 0.5); text-decoration:none; display:block; text-align:center;}
.auditstep li.active .count strong{background-color:#0A0F2E; color:#ffffff;}
.auditstep li.active .count{border:2px solid #0A0F2E;}
.auditstep li.active .stepdetail{color:#0A0F2E;}
.auditstep li.complete .count strong{background-color:#5caa2d; color:#ffffff; font-size:0; background-image:url(../images/check-icon.svg); background-repeat:no-repeat; background-position:center;}
.auditstep li.complete .count{border:2px solid #5caa2d;}
.auditstep li.complete .stepdetail{color:#5caa2d;}
.auditstep li.complete::after, .auditstep li.complete::before{background-color:#f1f8ec;}
.auditstep li.active::before{background-color:#f1f8ec;}


.competition{padding:0;}
.competition h3{font-weight: 600; font-size: 18px; line-height: 22px; color: #000000; margin: 0 0 30px; text-align: center;}
.competitionbox{margin:10px 10px; background: #f4f3f6; border-radius: 10px;}
.competitionbox h5{font-weight: 500; font-size: 18px; line-height: 26px; color: #ffffff; background-color: #0A0F2E; margin:0; text-align: center; padding: 10px 15px; border-radius: 10px 10px 0 0;}
.competition-info ul li{font-weight: 400; font-size: 13px; margin: 20px 0; position: relative; padding: 0px 15px;}
.competition-info ul li span{font-weight: 700; position: absolute; right: 15px; top: 0; bottom: 0; margin: auto; color: #0A0F2E;}
.competition-link{border-top: 1px solid #dcdcdc;}
.competition-link ul{display: flex; justify-content: center; align-content: center;}
.competition-link ul li{width: 33.33%; text-align: center; padding: 10px 0px;}
.competition-link ul li a{font-weight: 500; font-size: 14px;  color: #0A0F2E;}
.competition-link ul li a i{display: block;}
.competitionslider .slick-prev, .competitionslider .slick-next{display: none !important;}
.competitionslider .slick-dots li{width: auto; height: auto; margin: 0 5px;}
.competitionslider .slick-dots li button{width: 10px; height: 10px; background-color: #0A0F2E; border-radius: 50%; opacity: 0.2;}
.competitionslider .slick-dots li.slick-active button{opacity: 1;}
.competitionslider .slick-dots li button::before{display:none;}

.accoutstat{padding:50px 0 30px;}
.accoutstat h3{font-weight: 600; font-size: 26px; line-height: 30px; color: #000000; margin: 0 0 30px; text-align: center;}
.accoutstat ul{display: flex; flex-wrap: wrap; justify-content: center;}
.accoutstat ul li{width: 16.66%; padding:0 20px 20px; text-align: center;}
.accoutstat ul li strong{display: block; font-weight: 700; font-size: 20px; line-height: 24px; color: #0A0F2E;}
.accoutstat ul li span{display: block; font-weight: 400; font-size: 14px; line-height: 18px; color: #000000;}


.heading-filter{display: flex; align-items: center; justify-content: space-between; padding:0 10px 15px;}
.competition .heading-filter h3{margin: 0;}
.competition .heading-filter h3 a{font-size: 16px; color: #000000; vertical-align: middle; margin-left: 5px; display: inline-block;}
.competition-info p{min-height: 251px; display: flex;align-items: center;  text-align: center; padding: 15px 20px;}

#teamprocess .modal-body{padding: 50px 20px;}
.favouriteicon{height: 60px; width: 60px; border-radius: 50%; margin: 0 auto 20px; color: #0A0F2E; border: 1px solid #0A0F2E; display: flex;align-items: center;justify-content: center;font-size: 30px;}
.tourstep h2{font-weight: 600; font-size: 36px; line-height: 40px; color: #000000; margin: 0 0 15px; text-align: center;}
.tourstep p{font-weight: 400; font-size: 16px; line-height: 24px; color: #444444; margin: 0 0 25px; text-align: center;}
.tourstep .tourstep-select{text-align: center; margin: 25px 0 25px;}
.tourstep-select .selectdropdown, .tourstep-select .form-control{width: 350px; text-align: center; margin: 0 auto 15px; border: 1px solid #0A0F2E; border-radius: 5px; background-color: #ffffff; padding: 6px 12px;}
.tourstep-select .form-control:focus{box-shadow: none;}
.nextbtn{text-align: center; display: flex;align-items: center; justify-content: center;}
.nextbtn a.btn-primary, .nextbtn a.border-btn{min-width: 150px; margin: 0 5px; font-size: 18px; padding: 10px 20px; height: auto;}
.nextbtn a.border-btn:focus{box-shadow: none;}

.minidashboard .gamebar_detail h2{margin: 0 0 5px;}
.minidashboard .gamebar_detail p{font-weight: 600; font-size: 18px; line-height: 24px; color: #ffffff; margin: 0 0 15px; padding: 0;}


.minidashboard .counter{width: 100%; padding: 10px 10px;}
.minidashboard .counter ul.showtime{display: flex; padding:15px 0 0 0;}
.minidashboard .counter p{font-size: 12px; line-height: 12px; cursor: pointer; position: relative; margin: 0;}
.minidashboard .counter p:after{position: absolute; content: ""; margin-left: 10px; width: 0; height: 0; top: 4px; right: auto; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #000000;}	
.minidashboard .counter ul{display: table-column; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}	

.helpsupport{display: flex; flex-wrap: wrap;}
.supportform{width: 70%; padding-right: 30px;}
.supportform iframe{width: 100%; height: 100%;}
.reachus-section{width: 30%; background-color:#0A0F2E; border-radius: 10px; padding: 20px 15px; color: #ffffff;}
.reachusbox{border-bottom: 1px solid rgba(255,255,255,0.5); padding: 0 0 15px; margin: 0 0 15px;}
.reachusbox:last-child{border-bottom:none; padding: 0 0 10px; margin: 0 0 10px;}
.reachusbox strong{font-weight: 600; font-size: 22px; line-height: 33px; display: block; color: #ffffff; margin:0 0 15px;}
.reachusbox ul li{padding: 0 0 5px 25px; position: relative; font-size: 16px;}
.reachusbox ul li a{color: #ffffff; text-decoration: none;}
.reachusbox ul li i{position: absolute; left: 0; top: 5px;}
.reachusbox .sponsor-social li{padding: 0 10px 0 0;}
.reachusbox .sponsor-social li a{width: 26px; height: 26px; background:#ffffff; color: #000000; font-size: 14px;}
.reachusbox .sponsor-social li a i{position: relative; left: auto; top: auto;}

.innerwhite-section .referlist .heading{margin: 0;}
.innerwhite-section .referlist .heading h2{color: #000000; margin: 0;}
.referlist .playercardlist ul{justify-content: center; margin:0 0 30px;}
.referlist .playercardbox{background-color: #f4f3f6;}
.referlist .playercardimg figure{background-color: #f4f3f6;}


.coupon_code{text-align: center; margin: 30px auto 50px; max-width: 500px; background:linear-gradient(180deg, #0B1449 0%, #090F35 100%); padding: 40px 0px; border-radius: 10px;}
.coupon_box{border-radius: 8px; border: 2px dashed #ffffff; padding:35px 15px; position: relative; z-index: 1; max-width: 300px; margin: 0 auto 35px;}
.coupon_box strong{margin: 0; font-size: 22px; line-height: 30px; color: #ffffff; display: block;}
.coupon_box a.copy_code{position: absolute; right: 5px; top: 3px; color:#ffffff;}
.share_refer .sponsor-social{margin: 10px auto 0; justify-content: center;}
.share_refer .sponsor-social li a{background: #ffffff; color: #000000;}
.share_refer h4{font-size: 22px; line-height: 30px; font-weight: 600; color: #ffffff; margin: 0;}
.share_refer p{font-weight: 400; font-size: 14px; line-height: 20px; margin: 0px 0px 35px 0px; color: #ffffff;}
.share_refer span{font-size: 16px; line-height: 20px; font-weight: 600; color: #ffffff; margin: 0; display: block;}

.refertable.table thead tr th{padding: 12px 20px;}
.refertable.table tbody tr td{padding: 8px 20px;}
.refertable.table thead tr th:last-child, .refertable.table tbody tr td:last-child{text-align: right;}
.refertable.table tbody tr td .playerinfo{display: flex; align-items: center;}


#howtoplay .btn-close{background: none; padding: 0; opacity: 1; position: absolute; right: 10px; top: 10px; height: 22px; width: 22px;}

.ruleaccordion.howtoplayaccordion .accordion-item{position: relative;}
.ruleaccordion.howtoplayaccordion .accordion-item .accordion-header{position: relative; margin-bottom: 0; padding: 15px 75px 15px 50px; font-weight: 600; font-size: 16px;}
.ruleaccordion.howtoplayaccordion .accordion-item .accordion-header strong{width: 28px;height: 28px;background-color: #0A0F2E;color: #ffffff;border-radius: 50%;display: inline-block;font-weight: 600;text-align: center;font-size: 16px;vertical-align: middle;line-height: 28px; position: absolute; left: 10px; top: 0; bottom: 0; margin: auto;}
.ruleaccordion.howtoplayaccordion .accordion-item .accordion-header > span{position: absolute; right: 50px; top: 0; bottom: 0; margin: auto; display: flex; align-items: center;cursor: pointer;}
.ruleaccordion.howtoplayaccordion .accordion-item .accordion-button{width: 50px; padding: 0; position: absolute; right: 0; top: 0; bottom: 0; margin: auto;}
.ruleaccordion.howtoplayaccordion .accordion-button::after{right: 15px; top: 0; bottom: 0; margin: auto; display: flex; align-items: center;}
.ruleaccordion.howtoplayaccordion .accordion-button:not(.collapsed){background-color: transparent; box-shadow: none;}
.ruleaccordion.howtoplayaccordion .accordion-button:not(.collapsed)::after{color: #444444;}
.ruleaccordion.howtoplayaccordion p{margin: 0;}
.ruleaccordion.howtoplayaccordion .accordion-body{padding: 0 15px 15px 15px;}

.addplayertable{max-height: 500px; overflow-y: auto;}
.tabledata .tab-content.actiontable .mytable tr:last-child td{border-bottom: 6px solid #ffffff; border-top: 6px solid #ffffff;}
.actiontable .table thead tr th{padding: 12px 18px;}
.actiontable .table thead tr th:last-child{text-align: right;}
.assignrole{display: flex; align-items: center; justify-content: flex-end;}
.assignrole a{display: flex; align-items: center; justify-content: center; height: 30px; width: 30px; border-radius: 6px; border: 1px solid #0A0F2E; color: #0A0F2E; margin: 0 5px; font-weight: 500; font-size: 12px;}
.assignrole a.active{border: 2px solid #0A0F2E; background-color: #0A0F2E; color: #ffffff;}
.tabledata .tab-content.actiontable{border-radius: 12px;}
.team-fieldview > ul > li > a{color: #ffffff;}
.captionoption{position: absolute; top: -7px; left: 2px; z-index: 1; width: 18px; height: 18px; background: #ffffff; border-radius: 50%; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; color: #0A0F2E; text-decoration: none; font-size: 10px; font-weight: 600;}
.deleteoption{position: absolute; top: -2px; right: 12px; z-index: 1; width: 20px; height: 20px; background: #ffffff; border-radius: 50%; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; color: #0A0F2E; text-decoration: none; font-size: 11px; font-weight: 600;}
.captain{background-color: #800080; color: #ffffff;}
.assignrole .captain.active{background-color: #800080; color: #ffffff; border: 1px solid #800080;}
.vicecaptain{background-color: #FFA500; color: #ffffff;}
.assignrole .vicecaptain.active{background-color: #FFA500; color: #ffffff; border: 1px solid #FFA500;}

.powerbtn .btn.btn-warning{padding: 0px 30px; background-color: #FFD43B; color: #000000; border: none;}
.powerbtn .btn.btn-warning i{margin: 0 8px 0 0;}
.powerbtn .btn.btn-warning:hover{background-color: #000000; color: #FFD43B;}
.powerbtn-box{display: flex; justify-content: center; align-content: center; flex-wrap: wrap;}
.powerbtn-box .btn{margin:10px 5px; padding: 5px 28px 5px 3px; border-radius: 31.5px; height: 50px; background-color: #b6b6b6; display: flex; align-items: center; font-weight: 600; font-size: 16px; line-height: 24px; color: #FFFFFF; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; min-width: 215px; position: relative;}
.powerbtn-box .btn:after {position: absolute; content: ''; right: 55px; height: 100%; width: 1px; background-color: rgba(255,255,255,0.4);}
.powerbtn-box .btn span{position: absolute; right: 15px; top: 0; bottom: 0; margin: auto; display: flex; align-items: center; justify-content: center;}
.powerbtn-box .btn figure{width: 42px; height: 42px; background: #000000; border-radius: 50%; display: flex; align-items: center; justify-content: center; opacity: 0.58; margin: 0 13px 0 0;}
.powerbtn.powerbtn-box{justify-content: space-between; margin: 0; padding: 0 30px; align-items: flex-end;}
.leftdata .powerbtn.powerbtn-box{bottom: 25px; max-width: 840px; margin: auto;}

.middle-section.p15{padding: 15px;}
.countdata{background-color:rgba(255,255,255,0.15); margin: 0 auto 20px; border-radius: 6px; position: relative; z-index: 1; padding: 30px 0px 10px 0px; max-width: 1920px;}
.countdata ul{display: flex; align-items: center; justify-content: center; padding:0;}
.countdata ul li{width: 20%; padding: 0 10px;}
.countbox{font-size: 22px; color: #ffffff; font-weight: 500; text-align: center; border-radius: 6px; padding: 32px 10px; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.countbox:hover{color: #000000; background: #8ffca0; background: -moz-linear-gradient(left, #8ffca0 0%, #0bcbfb 100%); background: -webkit-linear-gradient(left, #8ffca0 0%,#0bcbfb 100%); background: linear-gradient(to right, #8ffca0 0%,#0bcbfb 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8ffca0', endColorstr='#0bcbfb',GradientType=1 );}
.countbox strong{display: block; font-weight: 600; font-size: 40px; line-height: 40px; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.countbox:hover strong{color: #b51745;}

.gameweekdata{display: flex; flex-wrap: wrap; justify-content: space-between; position: relative; z-index: 1; margin: 0 auto 15px; max-width: 1920px;}
.leftdata{width: 70%; border-radius: 6px; padding: 50px 15px;}
.leftdata .groundbg img{max-height: 800px;}
.rightdata{width: 30%; padding: 0 0 0 30px;}
.quickdata{margin: 0 0 30px;}
.quickdata h4{font-weight: 600; font-size: 24px; color: #ffffff; margin: 0 0 15px;}
.quickbox{background-color:rgba(255,255,255,1); border-radius: 6px; padding: 20px 15px; height: 200px; overflow-y: auto;}
.quickbox ul li{font-weight: 500; font-size: 16px; line-height: 26px; padding: 3px 0; position: relative;}
.quickbox span{font-weight: 700; position: absolute; right: 0; top: 0; bottom: 0; margin: auto; color: #000000;}
.gameweektable{padding: 10px 10px; background-color: #ffffff; border-radius: 6px;}
.gameweektable .customscroll{height: 374px; overflow-y: auto;}
.gameweektable .table{margin: 0;}
.gameweektable .table a.scorecard{border: 1px solid #0b1449; border-radius: 6px; color: #0b1449; text-decoration: none; padding: 5px 5px; display: inline-block;}
.gameweektable .table a.scorecard img{margin:-5px 0px 0px 0px;}
.gameweektable .ranktable.table thead tr th, .gameweektable .ranktable.table tbody tr td{padding: 12px 15px;}
.gameweektable .ranktable.table thead tr th:first-child, .gameweektable .ranktable.table tbody tr td:first-child{text-align: left;}
.gameweektable .ranktable.table thead tr th:nth-child(2), .gameweektable .ranktable.table tbody tr td:nth-child(2){text-align: center;}
.gameweektable .ranktable.table thead tr th:last-child, .gameweektable .ranktable.table tbody tr td:last-child{text-align: right; width: 145px;}

.gameweek-playerlist{text-align: center;margin: 15px 0 0;}
.gameweek-playerlist p{color: #ffffff; font-size: 18px; font-style: italic; margin: 0;}
.gameweek-playerlist p a{color: #11a3ff; text-decoration: underline; font-weight: 600; display: inline-block;}


.filter_content{display: flex; justify-content: center; align-items: center; margin: 0 0 20px; position: relative; z-index: 1;}
.filter_content .form-control{max-width: 175px; border: 1px solid #c11744; padding: 14px 14px; font-size: 16px; font-weight: 600; margin: 0 10px;}
.filter_content .form-control:focus{box-shadow: none;}
.filter_content .choosen_selct{position: relative; width: 175px; margin: 0 10px;}
.filter_content .choosen_selct .form-control{background-color: #4f2a8c; color: #ffffff; border: 1px solid #ae89eb; width: 100%; padding-right: 30px;}
.filter_content .choosen_selct a{position: absolute; right: 5px; top: 0; bottom: 0; margin: auto; height: 14px; width: 14px;}
.filter_content .choosen_selct a img{vertical-align: top;}
.gameweek-filter h4{font-size: 24px; font-weight: 600; color: #ffffff; text-align: center; margin: 0 0 10px;}

.ruleaccordion.howtoplayaccordion .accordion-item .accordion-header{font-size: 14px;}

.playerimgbox{position: relative; max-width: 63px; margin: 0 auto 25px;}
.playerimgbox .more-option{right: -15px;}
.playerimgbox figure{height: 70px; width: 70px; margin: auto; background-color: #0A0F2E; border-radius: 4px; overflow: hidden; border: 1px solid #767676;}
.leadertag h5{display: flex; flex-wrap: wrap; justify-content: center; align-items: center;}
.leadertag i{background-color: #800080; color: #ffffff; border: 2px solid #800080; width: 24px; height: 24px; border-radius: 50%; font-size: 11px; font-weight: 600; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; font-style: normal;}
.leadertag .vicecaptaintag i{background-color: #FFA500;border: 2px solid #FFA500;}
.leadertag strong{font-size: 12px; background-color: #ffffff; padding: 5px 15px 4px 15px; margin: 0 0 0 10px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; font-weight: normal;}
.leadertag h5 .available::after, .leadertag h5 .unavailable::after{left: -10px; top: 4px; width: 6px; height: 6px;}



.winnerbox-section ul{display: flex; flex-wrap: wrap; margin: 50px 0 0;}
.winnerbox-section ul li{width: 20%; padding: 0 7px 15px;}
.winnerbox-section .winnerbox{background-color: #ffffff; -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); border-radius: 10px; padding: 15px 10px 15px; height: 100%; position: relative;}
.playerrank{position: absolute; left: 0; right: 0; margin:-40px auto 0; color: #000000; font-size: 16px; font-weight: 600; height: 30px; width: 30px; border-radius: 50%; background-color: #FFD43B; text-align: center; display: flex; justify-content: center; align-items: center;}
.winnerbox-section .winnerbox figure{text-align: center; margin: 0 auto 25px; height: 200px; width: 200px; overflow: hidden;}
.winnerbox-section .winnerbox figure img{max-height: 100%;}
.winnerbox-section .winnerbox h4{font-weight: 600; font-size: 18px; line-height: 20px; color: #000000; margin: 0; text-align:center;}
.winnerbox-section .teamname{text-align: center; font-size: 14px;}
.winnerbox-section strong{display: block; text-align: center; padding: 10px 0 0;}


.sponsor_list.bonus-card > li{width: 50%;}
.sponsor_list.bonus-card .sponsortop{padding: 0 0 20px 0;}
.sponsor_list.bonus-card .sponsorinfo{padding: 0 0 0 90px;}
.sponsor_list.bonus-card .sponsorinfo figure{padding: 6px;}
.sponsor_list.bonus-card .sponsorslide{height: 100%;}
.sponsor_list.bonus-card .sponsorslide .sponsortop strong{display: block; margin:0 0 10px;}
.sponsor_list.bonus-card .sponsorslide p{margin:0 0 5px;}
.sponsor_list.bonus-card .topplayerlist ul{height: auto; max-height: 310px;}
.gameweektag{background: #0A0F2E; border: 1px solid #0A0F2E; color: #ffffff; border-radius: 5px; padding: 6px 14px; font-size: 14px; line-height: 18px; display: inline-block; margin-right: 5px;}

.notification-listing > ul > li{font-size:13px; display:block; padding:15px 15px 15px 80px; position:relative; border-radius: 10px; margin-bottom: 10px; background:#ffffff; border: solid 1px #d5d5d5;}
.notification-listing > ul > li.unread{background:#0A0F2E; border:1px solid #0A0F2E;}
.notification-listing > ul > li.unread h3, .notification-listing > ul > li.unread p, .notification-listing > ul > li.unread span{color:#ffffff;}
.notification-listing > ul > li.unread figure{background-color: #ffffff; border: 1px solid #ffffff;}
.notification-listing > ul > li figure{position:absolute;left:15px;top:15px; width:50px; height:50px; overflow: hidden; border-radius: 50%; background-color: #ffffff; border: 1px solid #ddd; display: flex; align-items: center; justify-content: center;}
.notification-listing > ul > li figure img{height: 24px; width:24px;}
.notification-listing > ul > li h3{font-size: 18px; font-weight:600; margin-bottom: 8px;}
.notification-listing > ul > li p{margin-bottom: 7px; font-size: 16px; line-height: 1.2;}
.notification-listing > ul > li span{font-size: 13px; color: #7d7d7d; display:inline-block;}

.blogbox.noborder .blogcontent{padding: 0px 0 15px 0;}

.pricing-row{display: flex; justify-content: center; flex-wrap: wrap; margin-bottom: 50px;}
.feature-plan{padding: 25px 0px 0px 0px; overflow: hidden; margin: 30px 10px 0 10px; width: 450px; max-width: 100%;}
.pricing-card{-webkit-box-shadow: 0 1px 16px rgba(0,0,0,.12); box-shadow: 0 1px 16px rgba(0,0,0,.12); border-radius: 10px; position: relative; padding: 0 15px; background-color: #0A0F2E;}
.pricing-card-header{text-align: center;}
.pricing-card-header h3{font-weight: 600; font-size: 18px; line-height: 30px; color: #ffffff; margin:0; border-radius: 6px; background-color: #1877F2; display: inline-block; padding: 10px 30px; position: relative; top: -25px; z-index: 10;}
.pricehead{display: flex; align-items: center; justify-content: space-between; margin: 20px 0 25px;}

.pricehead strong{font-size:20px; font-weight: 700; color: #ffffff;}
.pricehead strong span{font-weight: 400;}
.pricehead .btn-primary{font-size: 14px; padding: 8px 18px;}
.pricing-card p small{font-size:13px; display: block; color: #ffffff; text-align: center;}
.plan-item{padding:0 0 25px;}
.plan-item h4{font-weight: 700; font-size: 20px; line-height: 30px; margin:0 0 15px; color: #ffffff;}
.plan-item > ul{margin: 0 0 20px;}
.plan-item > ul > li{font-size: 15px; padding: 3px 0 3px 0; position: relative; color: #ffffff;}
.plan-item ul li p{color: #ffffff; margin:0;}
.plan-item ul li p i{margin-right: 10px;}
.pricing-card .border-btn{height: auto; padding: 8px 15px; font-size: 15px; line-height: 27px;}
.pricing-card .border-btn{border: 1px solid #ffffff;}
.plan-feature-list{padding: 5px 0px 15px 35px; list-style: disc;}
.plan-feature-list li{font-size: 13px; padding: 0 0 3px; color: #ffffff;}
.detailplan{position: absolute; bottom: -100%; left: 0; right: 0; border-radius: 10px; margin: auto; background-color: #0A0F2E; overflow-y: auto; z-index: 9; height: 100%; padding: 40px 15px 20px 15px; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.detailplan .plan-item{overflow-y: auto; height: calc(100% - 50px);}
.pricing-card.open .detailplan{bottom: 0;}
.detailplan .mCSB_scrollTools .mCSB_draggerRail{background-color: #ffffff; filter: "alpha(opacity=100)"; -ms-filter: "alpha(opacity=100)";}
.detailplan .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{width: 3px;}
.detailplan .mCSB_scrollTools{opacity: 1;}

.innerwhite-section #pricing.tab-content {background: transparent; padding: 0; margin: 0;}



.bracket-battle-heading{padding:0 0 10px 0;}
.bracket-battle-heading button{border: none; display: block; width: 100%; background-color: transparent; font-weight: 600; font-size: 18px; line-height: 22px; margin:0; text-align: left; position: relative; padding: 0px 20px 0px 0;}
.bracket-battle-heading button:after{position: absolute; content: ""; height: 8px; width: 16px; top: 0; bottom: 0; right: 0; margin: auto; background: url("../images/bottom-arrow-blue.png") no-repeat; background-size: 16px; background-position: center right; -webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); -moz-transform: rotate(180deg); transform: rotate(180deg);}
.bracket-battle-heading button.collapsed:after{-webkit-transform: rotate(0); -ms-transform: rotate(0); -moz-transform: rotate(0); transform: rotate(0);}
.bracket-battle-heading p{margin: 0; padding: 10px 0 0; text-align: left;}

.outerresult button{border: none; display: block; width: 100%; background-color: transparent; font-weight: 600; font-size: 18px; line-height: 22px; margin:0 0 10px; text-align: left; position: relative; padding: 0px 20px 0px 0;}
.outerresult button:after{position: absolute; content: ""; height: 8px; width: 16px; top: 0; bottom: 0; right: 0; margin: auto; background: url("../images/bottom-arrow-blue.png") no-repeat; background-size: 16px; background-position: center right; -webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); -moz-transform: rotate(180deg); transform: rotate(180deg);}
.outerresult button.collapsed:after{-webkit-transform: rotate(0); -ms-transform: rotate(0); -moz-transform: rotate(0); transform: rotate(0);}
.outerresult ul{display: flex; flex-wrap: wrap; justify-content: center;}
.outerresult ul li{padding: 0 15px 30px;}
.result_inner{width: 350px; background: #0A0F2E; -webkit-box-shadow: 1px 1px 5px 3px rgba(0,0,0,.1); box-shadow: 1px 1px 5px 3px rgba(0,0,0,.1); border-radius: 10px; padding: 15px;
position: relative; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.price_image{width: 80px; height: 80px; margin:0 auto 20px;}
.result_inner .price_image img{-webkit-filter: brightness(100); filter: brightness(100);}
.result_inner h5{font-weight: 600; font-size: 20px; line-height: 20px; color: #ffffff; margin: 0 0 10px; text-align: center; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.result_inner h6{text-align: center; margin: 0; font-weight: 600; font-size: 16px; color: #ffffff; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.result_inner:hover{background: #ffffff;}
.result_inner:hover .price_image img{-webkit-filter: brightness(1); filter: brightness(1);}
.result_inner:hover h5, .result_inner:hover h6{color: #0A0F2E;}

.match_round{background: #0A0F2E; border-radius: 10px; padding: 5px 0; display: flex; color: #ffffff; justify-content: center;}
.match_round .round_list{width: 20%; text-align: center; border-right: 1px solid #ffffff; padding: 10px 9px;}
.match_round .round_list h5, .match_round .round_list h6{margin: 0;}
.match_round .round_list h6 a{color: #ffffff; text-decoration: none;}
.match_round .round_list:last-child{border-right: none;}

.battlesection{overflow-y: auto; padding-bottom: 20px;}
.battlesection .match_round, .battlesection .outer-part{min-width: 1650px;}

.chain-cols{margin: 0; width: 205px; padding: 0px; position: relative;}
.outer-part{display: flex; align-items: center;}
.grid-outer{width: 16.66%;}
.grid-outer.second-grid .rount-point{margin: 63px 0; padding: 0;}
.grid-outer.second-grid .chain-cols:first-child .rount-point:first-child{margin-top: 0; margin-bottom: 58px;}
.grid-outer .chain-cols:last-child .rount-point{margin-bottom: 0;}
.second-grid .chain-cols:nth-child(2n) .verticleline{bottom: 30px; height: 100%;}
.second-grid .chain-cols:nth-child(2n+1) .verticleline{height: 100%; top: 0px; bottom: auto;}
.second-grid .chain-cols:nth-child(2n+1) .verticleline{top: -32px;}
.second-grid .chain-cols:nth-child(2n+1) .verticleline::before{top: 0;}
.grid-outer.second-grid .chain-cols small.sub-child-arrow{top: 94px;}

.grid-outer.third-grid .rount-point{margin: 189px 0; padding: 0;}
.grid-outer.third-grid .chain-cols:first-child .rount-point:first-child{margin-top: 0;}
.grid-outer.third-grid .chain-cols .verticleline{height: 128px;}
.grid-outer.third-grid .chain-cols:nth-child(2n+1) .verticleline{transform: translateY(31px); top: 0; bottom: auto;}
.grid-outer.third-grid .chain-cols:nth-child(2n) .verticleline{bottom: 28px; top: auto;}
.grid-outer.third-grid .chain-cols:nth-child(2n+1) small.sub-child-arrow{top: 157px;}


.grid-outer.fourth-grid .rount-point{margin: 441px 0; padding: 0;}
.grid-outer.fourth-grid .chain-cols:first-child .rount-point:first-child{margin-top: 0;}
.grid-outer.fourth-grid .chain-cols .verticleline{height: 255px;}
.grid-outer.fourth-grid .chain-cols:nth-child(2n+1) .verticleline{transform: translateY(31px); top: 0; bottom: auto;}
.grid-outer.fourth-grid .chain-cols:nth-child(2n) .verticleline{bottom: 28px; top: auto;}
.grid-outer.fourth-grid .chain-cols:nth-child(2n+1) small.sub-child-arrow{top: 283px;}


.grid-outer.fifth-grid .rount-point{margin: 945px 0; padding: 0;}
.grid-outer.fifth-grid .chain-cols:first-child .rount-point:first-child{margin-top: 0;}
.grid-outer.fifth-grid .chain-cols .verticleline{height: 506px;}
.grid-outer.fifth-grid .chain-cols:nth-child(2n+1) .verticleline{transform: translateY(31px); top: 0; bottom: auto;}
.grid-outer.fifth-grid .chain-cols:nth-child(2n) .verticleline{bottom: 28px; top: auto;}
.grid-outer.fifth-grid .chain-cols:nth-child(2n+1) small.sub-child-arrow{top: 534px;}

.grid-outer.sixth-grid .rount-point{margin: 1952px 0; padding: 0;}
.grid-outer.sixth-grid .chain-cols:first-child .rount-point:first-child{margin-top: 0;}
.grid-outer.sixth-grid .chain-cols .verticleline{height: 1010px;}
.grid-outer.sixth-grid .chain-cols:nth-child(2n+1) .verticleline{transform: translateY(31px); top: 0; bottom: auto;}
.grid-outer.sixth-grid .chain-cols:nth-child(2n) .verticleline{bottom: 28px; top: auto;}
.grid-outer.sixth-grid .chain-cols:nth-child(2n+1) small.sub-child-arrow{top: 1037px;}

.grid-outer.seventh-grid .rount-point{margin: 3968px 0; padding: 0;}
.grid-outer.seventh-grid .chain-cols:first-child .rount-point:first-child{margin-top: 0;}
.grid-outer.seventh-grid .chain-cols .verticleline{height: 2018px;}
.grid-outer.seventh-grid .chain-cols:nth-child(2n+1) .verticleline{transform: translateY(31px); top: 0; bottom: auto;}
.grid-outer.seventh-grid .chain-cols:nth-child(2n) .verticleline{bottom: 28px; top: auto;}
.grid-outer.seventh-grid .chain-cols:nth-child(2n+1) small.sub-child-arrow{top: 2046px;}


.rount-point{display: flex; align-items: center; position: relative; box-sizing: border-box; width: calc(100% - 25px);}
.point-count{font-size: 10px; width: auto; padding:0; color: #0A0F2E; text-align: center; font-weight: 500}
.tabslist {list-style: none; padding: 0; margin: 0; width: 100%; overflow: hidden;}
.tabslist > li{display: flex; align-items: center; font-size: 10px; background: #0A0F2E; color: #ffffff; margin: 1px 0; line-height: 1; cursor: grab; width: 100%; justify-content: space-between; border-radius: 15px;}
.tabslist > li img{width: 22px; height: 22px; border-radius: 100%; padding: 1px; margin-left: 4px;}
.name-fs{padding: 10px 7px; flex-grow: 1; white-space: nowrap; max-width: 100px; overflow: hidden; text-overflow: ellipsis;}
.rankcount{padding: 10px 4px; min-width: 40px; text-align: center; box-sizing: border-box; background: #000000; border-radius: 0 15px 15px 0;}
span.rankcount.winnerPlayer{background-color: #4c9545;}
span.rankcount.orange{background-color: #BC1742;}

.hover-part{align-items: center; transition: all ease-in-out .3s; -webkit-transition: all ease-in-out .3s; position: absolute; right: 0; display: none; overflow: hidden; background: #FFD43C; box-sizing: border-box; z-index: 99; top: 1px; bottom: 1px; color: #000000; border-radius: 0 13px 13px 0;}
.rount-point:hover .hover-part{display: flex; right: 0px;}
.hover-part a{color: #000000; text-decoration: none; font-size: 14px; display: block; padding: 20px 13px;}

.child-arrow-point {position: absolute; right: 0px; top: 0; bottom: 0;}
.chain-cols:nth-child(2n+1) .verticleline{height: 50%; background: #0A0F2E; width: 2px; display: inline-block; transform: translateY(100%); position: absolute; right: 8px;}
.chain-cols:nth-child(2n+1) .verticleline::before {content: ""; height: 2px; position: absolute; width: 10px; background: #0A0F2E; right: 0; top: 0;}
.chain-cols:nth-child(2n+1) small.sub-child-arrow {width: 10px; position: absolute; height: 2px; display: inline-block; background: #0A0F2E; top: 100%; right: 0px;}
.chain-cols:nth-child(2n) .child-arrow-point{top: 0px;}
.chain-cols:nth-child(2n) .verticleline {height: 50%; background: #0A0F2E; width: 2px; display: inline-block; transform: translateY(0%); position: absolute; right: 8px;}
.chain-cols:nth-child(2n) .verticleline::before{content: ""; height: 2px; position: absolute; width: 10px; background: #0A0F2E; right: 0; bottom: 0;}
.verticleline::after{height: 2px;}


.match_detail ul{display: flex; flex-wrap: wrap; justify-content: center; align-items: center;}
.match_detail ul li{padding:30px 15px;}
.matchdetailbox{width: 280px; background: #0A0F2E; -webkit-box-shadow: 1px 1px 5px 3px rgba(0,0,0,.1); box-shadow: 1px 1px 5px 3px rgba(0,0,0,.1); border-radius:10px 10px 0 0; padding: 15px;
position: relative; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.matchdetailbox h5{font-weight: 600; font-size: 20px; line-height: 20px; color: #ffffff; margin: 0 0 10px; text-align: center;}
.match_detail ul li h6{padding: 10px 15px; margin: 0; color: #ffffff; text-align: center; border-radius: 0 0 10px 10px;}
.match_detail ul li h6.winnerPlayer{background-color: #4c9545;}
.match_detail ul li h6.orange{background-color: #BC1742;}
.match_vs{display: flex; width: 50px; height: 50px; border-radius: 50%; background-color: #000000; justify-content: center; align-items: center; color: #ffffff; font-size: 22px; margin: auto;}

#timeline{background-color: transparent;}
.roadmap{position: relative; overflow-y: auto; max-height: 950px;}
.roadmaplist > li{padding: 0 0 15px 120px; position: relative;}
.roadmaplist > li:last-child{padding-bottom: 0;}
.roadmapbox{background-color: #ffffff; padding:15px 15px; position: relative; border-radius: 10px; text-align: left; z-index: 1;}
.roadmapbox:after{position: absolute; content: ""; left: -70px; top: 20px; height: 100%; width: 4px; background-color: #ffffff;}
.roadmaplist > li:last-child .roadmapbox:after{display: none;}
.roadmaplist > li > span{display: flex; justify-content: center; align-items: center; text-align: center; position: absolute; left: 0; z-index: 1; top: 0; font-size: 14px; font-weight: 600; background-color: #ffffff; color:#0A0F2E; padding: 3px 10px; border-radius: 4px;}


.timelinebox{}
.timelinebox-content{display: flex; align-items: flex-start; justify-content: space-between;}
.playerprofile{width: 80%; padding-right: 20px; margin: 0 0 10px;}
.playerscore{width: 20%; background-color: #0A0F2E; border-radius: 10px; color: #ffffff; display: flex; align-items: center; justify-content: center; text-align: center; flex-direction: column; padding:40px 20px;}
.playerscore strong{font-size: 40px; line-height: 40px; font-weight: 700;}


.playerprofile a{display: flex; align-items: center; margin: 0 0 10px;}
.playerprofile figure{background-color: #165357; width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: flex-end; overflow: hidden;}
.playerprofile h4{font-weight: 600; font-size: 18px; line-height: 21px; color: #000000; margin: 0 0 0 15px;}
.aboutplayer p{color: #000000; font-size: 14px; font-weight: normal; margin: 0 0 10px;}
.aboutplayer ul{display: flex; align-items: center; justify-content: space-between;}
.aboutplayer ul li a{color: #444444; display: block; border-radius: 4px; text-align: center; font-size: 14px;}
.aboutplayer ul li a i{margin: 0 5px 0 0;}

.otheroption {padding: 5px 0; border-top: 1px solid #E2E2E2; border-bottom: 1px solid #E2E2E2; margin: 15px 0 15px;}
.otheroption ul{display: flex; align-items: center; justify-content: space-between;}
.otheroption ul li{width: 33.33%; padding: 0 3px;}
.otheroption ul li a{color: #444444; display: block; padding: 5px 0; border-radius: 4px; text-align: center; font-size: 14px;}
.otheroption ul li a:hover{background-color: #f4f3f6;}
.otheroption ul li a i{margin: 0 10px 0 0;}

.commentlist ul{padding:0 0px 15px 0px; border-bottom: 1px solid #E2E2E2;}
.commentlist ul li{position: relative; padding: 0 0 0 50px; min-height: 40px; margin: 0 0 15px; display: flex; align-items: flex-start; flex-direction: column;}
.commentlist ul li:last-child{margin:0;}
.commentlist ul li figure{background-color: #165357; width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: flex-end; overflow: hidden; position: absolute; left: 0; top: 0;}
.commentlist ul li h4 {font-size: 14px; font-weight: 700; margin: 0;}
.commentlist ul li p{color: #000000; font-size: 13px; font-weight: normal; margin: 0;}

.commentoption{display: flex; align-items: center;}
.commentoption figure{background-color: #165357; width: 40px; height: 40px; border-radius: 50%; margin-right: 15px; display: flex; align-items: flex-end; overflow: hidden;}
.commentoption .form-control{background-color: #f4f3f6; border: none; border-radius: 50px; font-size: 14px; padding: 6px 15px; height: 40px;}
.commentoption .form-control:focus{box-shadow: none; border: none;}

.column{display: flex; justify-content: space-between;}
.left-column{width: 50%;}
.right-column{width: 50%; padding-left: 20px;}
.column h4{font-size: 20px; margin: 0 0 10px; font-weight: 700;}
.column ul li{font-size:14px; padding:3px 0 3px 25px; position: relative;}
.column ul li i{position: absolute; left: 0; top: 3px;}
.column ul li i img{max-width: 16px; max-height: 16px; margin: auto;}
.column ul li i.closeimg img{max-width: 12px; max-height: 12px; margin-left: 2px;}
.column .unavailable:after, .column .available::after{left: 4px; top: 6px;}
.column .fa-heart{color: #FF372B;}
.column .fa-heart:before{position: absolute; top: 5px; left: 0;}
.column .captain{display: block; height: 10px; width: 10px; border-radius: 2px; background-color: #800080; margin: 6px 0 0 4px;}
.column .vice-captain{display: block; height: 10px; width: 10px; border-radius: 2px; background-color: #FFA500; margin: 6px 0 0 4px}

.legend-btn .btn-primary {font-size: 14px; padding: 4px 15px 6px 15px;}
.column.legend{margin: 0 -10px;}
.column.legend .left-column, .column.legend .right-column{padding: 0 10px;}
.column.legend ul li{padding: 0px 0px 3px 0px; display: flex; justify-content: space-between;}
.column.legend ul li strong{background-color: #0A0F2E; width: 30%; color: #ffffff; font-weight: normal; padding: 7px 10px;}
.column.legend ul li span{background-color: #f4f3f6; width: 70%; color: #000000; padding: 7px 10px;}

#bonuscard .sponsorinfo{margin: 0 0 15px;}
#bonuscard .sponsorinfo strong{display: block; width: 100%;}

.find-club{display: flex; align-items: center; justify-content: space-between; margin: 0 0 24px;}
.find-club .game-searchbar{margin: 0; max-width: 100%; width: 500px;}
.find-club .heading{margin: 0;}
.find-club .heading h4{font-weight: 600; font-size: 18px; line-height: 22px; margin: 0;}

.step-collapse{margin-bottom: 24px;}
.step-collapse h2{margin: 0; font-weight: 600; font-size: 18px; line-height: 22px;}
.step-collapse h2 a{display: block; text-decoration: none; color: #000000; position: relative;}
.step-collapse h2 a:after{position: absolute; content: ""; height: 8px; width: 16px; top: 0; bottom: 0; right: 0; margin: auto; background: url("../images/bottom-arrow-blue.png") no-repeat; background-size: 16px; background-position: center right; -webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); -moz-transform: rotate(180deg); transform: rotate(180deg);}
.step-collapse h2 a.collapsed:after{-webkit-transform: rotate(0); -ms-transform: rotate(0); -moz-transform: rotate(0); transform: rotate(0);}
.cms-section .step-collapse .whitebox{padding: 15px 20px;}

.venuetitle{margin: 0 0 10px; font-weight: 600; font-size: 18px; line-height: 22px;}
.venuetitle a{display: block; text-decoration: none; color: #000000; position: relative;}
.venuetitle a:after{position: absolute; content: ""; height: 8px; width: 16px; top: 0; bottom: 0; right: 0; margin: auto; background: url("../images/bottom-arrow-blue.png") no-repeat; background-size: 16px; background-position: center right; -webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); -moz-transform: rotate(180deg); transform: rotate(180deg);}
.venuetitle a.collapsed:after{-webkit-transform: rotate(0); -ms-transform: rotate(0); -moz-transform: rotate(0); transform: rotate(0);}

.customselect.form-control{background-image: url("../images/bottom-arrow-blue.png"); background-repeat: no-repeat; background-size: 12px; background-position: 96% center; padding-right: 25px;}
.dateinput.form-control{background-image: url("../images/calendar-icon.svg"); background-repeat: no-repeat; background-size: 22px; background-position: 96% center; padding-right: 35px;}
.loginform textarea.form-control{height: 120px; resize: none;}

.gamepas-content{display: flex; flex-wrap: wrap;}
.gamepas-feature{width: 50%; padding-right: 35px;}

.gamepas-feature h3{font-weight: 700; font-size: 24px; margin: 0 0 15px; color:#4f2a8c;}
.gamepas-feature p{font-weight: 400; font-size: 14px; line-height: 20px;}
.gamepas-feature ul{margin: 20px 0 0;}
.gamepas-feature ul li{position: relative; width: 100%; padding: 0 0 10px 28px; font-weight: 400; font-size: 14px; line-height: 20px; color: #444444;}
.gamepas-feature ul li i{position: absolute; left: 0; top: 4px;}
.gamepas-content .loginform{width: 50%; max-width: 100%; margin:0 auto; background: #f8f8f8; padding: 25px 30px; border: 1px solid #ddd; border-radius: 10px;}
.gamepas-content .loginform .styled-selectors{font-size: 14px;}
.gamepas-content .loginform .btn-primary{width: 200px; background-color: #4f2a8c; border-color: #4f2a8c; margin: auto; display: block;}
.gamepas-content .loginform .btn-primary:hover{color: #fff;}
.gamepasscancellation li{position: relative; padding:8px 0 8px 20px; font-weight: 400; font-size: 16px; line-height: 24px; color: #444444;}
.gamepasscancellation li:after{position: absolute; content: ""; left: 0; top: 16px; height: 8px; width: 8px; border-radius: 50%; background-color: #444444;}

.playerprofile-section .loginform{width: 750px; margin:0 auto 0; max-width: 100%;}
.playerprofile-section .loginform .btn-primary{width: 200px; margin: auto; display: block;}
.clublistselect{margin: 0 0 20px;}
.clublistselect .customselect.form-control{width: auto; margin-left: auto; border: none; padding: 0; padding-right: 25px; font-weight: 600; font-size: 18px; line-height: 18px; color: #000000;}

#gameprofile .gameprofile{display: block; position: relative; top: auto; height: auto; right: auto;}
#gameprofile .gameprofile .gameprofileclose{display: block; position: absolute; left: 5px; top: 5px; z-index: 9; width: 36px; height: 36px; background-color: #ffffff; border-radius: 50%; padding: 5px 10px;}
#gameprofile .profile-content{padding: 0 20px 20px;}

.scoresupdated{font-size: 14px; margin: 10px 0px 5px 0px;}
.match-statistics{font-size: 0; margin: 0 0 15px;}
.match-statistics li{padding: 0 0 10px; font-size: 14px;}
.match-statistics li h5{font-weight: 600; font-size: 16px; line-height: 20px; color: #000000; margin: 0 0 5px;}
.match-statistics li span{display: inline-block; background-color: #f4f3f6; margin: 0 8px 10px 0; border:1px solid #0A0F2E; color: #0A0F2E; font-size: 14px; padding:4px 15px; border-radius: 4px;}
.match-statistics li span b{font-weight: normal;}

.subscribe_section{padding: 30px 0; text-align:center;}
.votelist{display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; margin:0 -10px; padding: 25px 10px;}
.votelist li{width: 33.33%; padding: 0 10px;}
.votelist li label{font-weight: 600; font-size: 16px; color: #000000; margin: 0 0 5px;}
.votelist li .customselect{position: relative;}
.votelist li .customselect:after{position:absolute; content: ""; right: 10px; top: 0; bottom: 0; margin: auto; width: 14px; height: 6px; background: url("../images/bottom-arrow.png") no-repeat; background-size: 12px;}
.votelist li .form-control{font-size: 16px; height: 50px; border: none; border-radius: 5px; font-weight: 600; color: #fff;}
.votelist li .form-control option{color: #000;}
.votelist li .form-control.threevote{background: linear-gradient(100deg, #F86BDF 7.44%, #7F5DD9 61.78%);}
.votelist li .form-control.twovote{background: linear-gradient(100deg, #F87A7A 8.24%, #D96AA0 57.92%);}
.votelist li .form-control.onevote{background: linear-gradient(100deg, #FA7E69 10.84%, #FCCF58 87.37%);}
.votelist li .form-control:focus{box-shadow: none;}
.vote-btn{background: #FFD43C; border: 1px solid #FFD43C; border-radius: 6px; color: #000000; font-style: normal; font-weight: 600; font-size: 16px; line-height: 20px; padding: 6px 15px; display: inline-block; position: relative; left: -15px;}
.vote-btn img{max-width: 16px; margin-right: 5px;}
.vote-btn:hover{color: #000000;}

.roundheading{padding: 10px 0;}
.roundheading span{display: block; text-align: center; max-width: 180px; font-weight: 600; font-size: 16px; line-height: 16px; color: #CA1744;}

.roundinfomodal{display: flex; flex-wrap: wrap; justify-content: center;}
.roundinfomodal .chain-cols{width: 190px; padding: 0px 10px 10px 10px;}
.roundinfomodal .chain-cols .rount-point{width: 100%;}

.tabledata .game-state{display: none;}

h5.pitch_message{text-align: center; color: #fff; padding: 0px 0px 5px 0px; display: none;}


@media (min-width:1200px) {
.down-arrow{position: absolute; bottom: 0; top: 0; cursor: pointer; z-index: 9; width: 22px; height: 22px; right: 15px; margin: auto;}
.down-arrow img{width: 22px;}
.down-arrow.show-more img{-webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); -moz-transform: rotate(180deg); transform: rotate(180deg);}
.smallbanner .banner-content{min-height: 70px; padding:15px 30px;}
.smallbanner .left-section > figure{height: 70px; width: 70px;}
.smallbanner .gamebar_detail .game-btn{position: absolute; top: 0; right: 50px; bottom: 0; margin: auto;}
.smallbanner .right-section{display: none;}
.smallbanner .custom-select{font-size: 20px; max-width: 500px; margin: 0;}
.smallbanner .gamebar_detail{padding-left: 30px;}
}



@media (max-width:1399px){
.gameweektable .ranktable.table thead tr th, .gameweektable .ranktable.table tbody tr td{padding: 12px 10px; font-size: 12px;}
.gameweektable .ranktable.table thead tr th:last-child, .gameweektable .ranktable.table tbody tr td:last-child{width: 125px;}
}
@media (max-width:1199px){
.home-banner .banner-content span{font-size: 20px; line-height: 20px;}
.home-banner .banner-content h1{font-size: 45px; line-height: 50px;}
.home-banner .banner-content h2{font-size: 18px; margin-bottom: 30px;}
.banner_btn .border-btn{font-size: 16px;}
.app_user_section ul li{padding-right: 10px;}	
.app_user_section ul li:last-child{padding-right: 0;}	
.app_user_section ul li .teams_box .teams_images{flex: 0 0 120px; max-width: 120px;}
.app_user_section ul li .teams_box .teams_content_box strong{font-size: 55px; line-height: 55px;}
.app_user_section ul li .teams_content_box span{font-size: 18px;}	
.app_user_section ul li .teams_box .teams_bottom {margin-left: -35px; padding: 7px 8px 7px 42px; min-height: 63px;}
.app_user_section ul li .teams_box .teams_bottom p{font-size: 13px; line-height: 15px;}	
.app_user_section ul li .teams_box .teams_content_box{padding-right: 10px;}
.gamebar_detail h2 {font-size: 26px; margin: 0;}
.supportform{width: 65%;}
.reachus-section{width: 35%;}
.countbox{font-size: 18px; padding: 22px 10px;}
.countbox strong {font-size: 30px; line-height: 30px;}	
.leftdata .groundview{padding: 0px 0px 0 0px;}
.leftdata{width: 100%;}
.gameweek-playerlist p{font-size: 14px;}	
.leftdata .groundbg img{max-height: 800px;}
.rightdata{width: 100%; padding: 30px 0 0 0;}
.gameweektable .ranktable.table thead tr th, .gameweektable .ranktable.table tbody tr td{padding: 12px 15px; font-size: 14px;}
.gameweektable .ranktable.table thead tr th:last-child, .gameweektable .ranktable.table tbody tr td:last-child{width: 145px;}
.leftdata .powerbtn.powerbtn-box{max-width: 700px;}
.winnerbox-section .winnerbox figure {height: 150px; width: 150px;}

.sponsor_list.bonus-card .sponsorinfo{padding: 0 0 0 70px;}

.feature-plan{width: 400px;}
.down-arrow{display: none;}
}
@media (max-width:991px){
.home-banner{padding: 30px 0;}	
.home-banner .banner-content span{font-size: 16px; line-height: 14px;}
.home-banner .banner-content h1{font-size: 35px; line-height: 35px;}
.home-banner .banner-content h2{font-size: 16px; margin-bottom: 16px;}
.banner_btn .border-btn{font-size: 14px; padding: 6px 15px;}
.introduction_section{padding: 30px 0; margin: 0 auto 20px;}
.introduction_box .left{flex: 0 0 100%; max-width: 100%;}
.introduction_box .right_content{flex: 0 0 100%; max-width: 100%;}
.introduction_box {flex-direction: column-reverse; flex-wrap: wrap;}
.introduction_box .left img{max-height: 350px;}
.introductionbox{padding: 15px 10px;}
.introductionbox figure {margin: 0 0 15px; width: 60px; height: 60px;}
.introductionbox h4{font-size: 16px;}
.introductionbox p{font-size: 14px; line-height: 18px;}
.features_section{padding: 25px 0 40px;}	
.introduction_box .right_content h3{font-size: 30px;}	
.heading h2{font-size: 30px;}	
.app_user_section{padding: 20px 0 25px 0;}	
.app_user_section ul{padding-top: 20px;}
.app_user_section ul li {padding:0 0 15px; flex: 0 0 100%; max-width: 100%;}
.app_user_section ul li .teams_box .teams_right_top{width: 100%;}
.app_user_section ul li .teams_box .teams_bottom {padding: 15px 10px 15px 30px; min-height: inherit; max-width: 100%; margin-left: -12px;}
.app_user_section ul li .teams_box .teams_content_box{padding-top: 25px; padding-right: 0;}
.gamebar_detail h2 {font-size: 20px;}
.auditstep li{padding: 0px 55px;}
.auditstep li::after{width: 95px;}	
.auditstep li::before{width: 95px}
.accoutstat ul li{width: 25%;}
.supportform{width: 55%;}
.reachus-section{width: 45%;}
.countdata ul li{padding: 0 5px;}	
.countbox{font-size: 15px; padding: 20px 5px;}
.countbox strong {font-size: 24px; line-height: 24px;}	
.quickdata h4{font-size: 20px;}
.leftdata{padding: 30px 15px;}	
.filter_content{margin: 0 0 20px;}	
.gameweek-filter h4{font-size: 20px; margin: 0;}	
.filter_content .form-control{font-size: 14px;}
.leftdata .powerbtn.powerbtn-box{max-width: 660px;}
.winnerbox-section .winnerbox figure{height: 195px; width: 195px;}	
.winnerbox-section ul li{width: 33.33%;}
.sponsor_list.bonus-card > li{width: 100%; padding: 0px 0px 20px;}	
.feature-plan{width: 300px;}
.result_inner{width: 300px;}

.match_detail ul li{padding:10px 5px;}
.matchdetailbox{width: 195px; padding:15px 10px 10px 10px;}
.price_image{margin: 0 auto 10px;}
.match_detail ul li h6{border-radius: 0 0 10px 10px;}	
.matchdetailbox h5{font-size: 16px; margin: 0 0 5px;}
.match_vs{font-size: 16px; width: 40px; height: 40px;}

.column{padding: 15px 10px 15px 10px;}
.find-club .game-searchbar{width: 350px;}	

.whitebox.gamepas-section{padding: 0; background-color: transparent;}
.gamepas-feature{width: 100%; background: #FFFFFF; -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0986943); border-radius: 10px; padding: 20px 20px 10px 20px; margin: 0 0 20px;}
.gamepas-content .loginform{width: 100%; background: #FFFFFF; padding: 20px 20px;}	

#votemodal .modal-dialog{max-width: 700px;}
}
@media (max-width:767px){
.home-banner{padding: 30px 0 0 0;}
.home-banner .banner-content span{font-size: 14px;}	
.home-banner .banner-content h1{font-size: 24px; line-height: 24px;}	
.home-banner .banner-content h2{font-size: 14px;}
.banner_btn{width: 100%; justify-content: center;}	
.home-banner .banner-caption{flex-wrap: wrap;}
.home-banner .banner-content{flex: 0 0 100%; max-width: 100%; align-items: center; text-align: center;}
.home-banner .banner_right{flex: 0 0 100%; max-width: 100%; text-align: center;}
.home-banner .banner_right img{max-height: 210px;}

.minidashboard .gamebar_detail h2{margin: 0 0 5px 50px;}


.introduction_section{padding: 0 0 30px 0;}	
.introduction_box .right_content p{font-size: 14px; line-height: 22px;}
.introduction_box .left img{max-height: 250px;}
.app_user_section ul li .teams_box .teams_content_box{padding-top: 10px;}
.introduction-section ul li{padding: 0 5px 10px; width: 50%;}

.cms-section{padding: 15px 0 25px 0;}
	
.auditstep {margin:25px auto 0; max-width: 540px;}	
.auditstep li{padding: 0px 5px; width: 25%;}	
.auditstep li .stepdetail{font-size: 12px;}	
.auditstep li::after{width: 55px;}
.auditstep li::before{width: 55px;}
.accoutstat{padding: 50px 0 0px;}
.gamebar_detail h2 {font-size: 15px;}
.competitionbox{margin: 10px 0;}	
.accoutstat ul li{width: 33.33%; padding: 0 10px 25px;}	
.accoutstat ul li strong {font-size: 18px; line-height: 22px;}
.accoutstat ul li span {font-size: 12px; line-height: 16px;}

#teamprocess .modal-body{padding: 30px 15px;}
.tourstep h2{font-size: 26px; line-height: 30px;}	
.tourstep-select .selectdropdown, .tourstep-select .form-control{width: 100%;}
.cms-section .game-searchbar{margin: 0px 0 15px; max-width: 100%;}
.competition .heading-filter h3 {font-size: 14px; line-height: 16px;}
.heading-filter{padding: 0 0 15px; flex-direction: column;}
.heading-filter .selectdropdown{margin-top: 10px; font-size: 14px;}

#editheading .gamelist ul {overflow-y: auto; max-height: 410px;}
.minidashboard .left-section{padding-left: 0;}
.minidashboard .gamebar_detail p{font-weight: 400; font-size: 12px; line-height: 14px; margin: 0 0 5px; padding: 0;}

.supportform{width: 100%; padding: 0 0 30px;}
.supportform iframe{height: 500px;}
.reachus-section{width: 100%;}

.ruleaccordion.howtoplayaccordion .accordion-item .accordion-header > span{right: 40px;}	
.ruleaccordion.howtoplayaccordion .accordion-item .accordion-button{width: 40px;}	
.ruleaccordion.howtoplayaccordion .accordion-button::after{right: 10px;}

.addplayertable{max-height: 450px; overflow-y: auto;}
.actiontable .table thead tr th {font-size: 12px;}
.caption a {height: 28px; width: 28px; border: 1px solid #0A0F2E; margin: 0 3px; font-size: 12px;}

.filter_content{margin: 0 0 10px;}	
.countdata{padding: 0px; margin: 0 auto;}
.countdata .container{padding: 0px;}
.countdata ul{padding:5px 0; flex-wrap: wrap;}	
.countdata ul li{padding: 0 7px; width: auto;}
.countbox{padding: 10px 0; font-size: 9px;}
.countbox strong{font-size: 16px; line-height: 16px;}
.gameweek-filter h4{font-size: 14px; font-weight: 400; color: #000000; background-color: #FFD43C; border-radius: 6px 6px 0 0; padding:5px 0px;}
.gameweek-filter h4 strong{font-weight: 700;}
.filter_content .form-control{padding: 8px 10px;}
.middle-section.p15{padding: 15px 5px;}	
.leftdata{padding:10px 5px;}
.leftdata .team-fieldview{bottom: 120px;}	
.leftdata .groundbg img{max-height: 580px;}
.gameweek-playerlist{padding: 0px 25px;}
.gameweek-playerlist p{font-size: 12px;}
.powerbtn-box .btn{min-width: 140px;}
.powerbtn-box .btn::after{right: 40px;}	

#playpower .powerbtn-box .btn{min-width: 200px;}
#playpower .powerbtn-box .btn::after{right: 50px;}
.leftdata .powerbtn.powerbtn-box{bottom: 10px;}
.playerimgbox{margin: 0 auto 15px;}	
.playerimgbox > figure{height: 50px; width: 50px;}
.playerimgbox .more-option{right: -2px;}	
.leadertag strong{font-size: 9px; padding: 4px 10px 4px 15px;}
.leadertag h5 .available::after, .leadertag h5 .unavailable::after{left: -8px; top: 2px;}
.leadertag i {width: 20px; height: 20px; font-size: 9px;}

.winnerbox-section .winnerbox figure{height: 150px; width: 150px;}
.winnerbox-section ul{margin: 0 -5px;}
.winnerbox-section ul li{width: 50%; padding: 0 5px 12px;}
.winnerbox-section ul li:first-child{width: 100%;}
.winnerbox-section ul li:first-child .winnerbox{max-width: 200px; margin-left: auto; margin-right: auto;}
.sponsor_list.bonus-card .sponsorslide .sponsortop strong{font-size:15px;}

.notification-listing > ul > li{padding: 15px 10px 15px 60px;}	
.notification-listing > ul > li figure {width: 40px; height: 40px; left: 10px;}	
.notification-listing > ul > li h3{font-size: 16px;}
.notification-listing > ul > li p {font-size: 14px;}

.pricing-card-header h3 {font-size: 16px; line-height: 22px; padding: 10px 40px; top: -20px;}

.feature-plan{width: 100%;}
.outerresult ul li{padding:15px 5px 15px; width: 50%;}
.result_inner{width: 100%; padding:15px 10px;}
.price_image{width: 50px; height: 50px; margin: 0 auto 10px;}
.result_inner h5{font-size: 16px; line-height: 16px; margin: 0 0 10px;}	
.result_inner h6{font-size: 14px;}	

.match_detail ul{flex-direction: column;}
.match_detail ul li{width: 100%;}
.matchdetailbox{width: 100%;}

.roadmaplist > li:after{position: absolute; content: ""; left: 0; top: -20px; height: 100%; width: 4px; background-color: #ffffff;}
.roadmaplist > li{padding: 0 0 15px 15px;}
.roadmapbox::after{left: auto; display: none;}
.roadmaplist > li > span{display: inline-block; position: relative; left: -20px; top: auto; padding: 5px 10px; margin: 0px 0px 15px 0px;}
.timelinebox{flex-direction: column-reverse;}
.playerprofile{width: 100%; padding:10px 0px 0px 0px; margin: 0;}
.playerscore{padding: 8px 15px 5px 15px; width: auto;position: absolute;right: 15px;top: 15px;font-size: 14px;}
.playerscore strong{font-size: 20px; line-height: 20px;}

.otheroption ul li a{font-size: 13px;}
.otheroption ul li a i{margin: 0 5px 0 0;}

.assignrole a {height: 30px; width: 30px; font-size: 12px;}
.column.legend ul li{font-size: 12px;}

#bonuscard .modal-body p{font-size: 14px; padding: 0px 5px;}
#bonuscard .sponsorinfo figure{top: 10px;}
.step-collapse{margin-bottom: 15px;}	
.step-collapse h2 {font-size: 14px; line-height: 16px;}
.cms-section .find-club{flex-direction: column; padding: 20px 15px; margin: 0 0 15px;}
.cms-section .find-club .game-searchbar{margin: 15px 0 0;}
.find-club .heading h4{font-size: 14px; line-height: 16px;}

	
#gameprofile .profile-content{padding: 0 10px 10px;}
.gamepas-feature h3{font-size: 20px;}

#votemodal .modal-dialog{max-width: 500px;}	
.votelist{margin:0; padding: 15px 5px;}
.votelist li{width: 100%; padding: 0 0 15px;}
.vote-btn{font-size: 12px; line-height: 12px; padding: 6px 10px; left: -10px;}

.bracket-battle-heading p {font-size: 14px;}	
.bracket-battle-heading{padding:0;}	
.bracket-battle-heading button, .outerresult button{font-size: 14px; margin: 0 0 5px;}

.roundinfomodal{padding: 15px 0;}
.roundinfomodal .chain-cols{width: 172px; padding: 0 5px 10px 5px;}

.battlesection .match_round, .battlesection .outer-part{min-width: inherit;}
.match_round{flex-wrap: wrap;}
.match_round .round_list{width: 33.33%; margin: 10px 0px; padding: 0;}
.outer-part.mt-4{display: none;}
.match_round .round_list h6{font-size:14px;}
.match_round .round_list h5{font-size:16px;}


.navbar-nav .mobiledropdownmenu.dropdown-menu{position: absolute; left: 0 !important; right: 0 !important;}
.header .navbar-collapse .mobiledropdownmenu.logindropdown.dropdown-menu::after{right: 38px; left: auto;}
.navbar-nav .mobiledropdownmenu.logindropdown.dropdown-menu {left: auto !important; right: 0 !important;}
.header .navbar-collapse .mobiledropdownmenu.dropdown-menu .dropdown-item{color:#000;}
.header .navbar-collapse .mobiledropdownmenu.dropdown-menu::after{right: auto; left: 25px;}
.tabledata .game-state{display: flex;}
.tabledata .game-state li{width: 25%;}

h5.pitch_message{display: block;font-size: 12px;}

.player_profile_modal{max-height: 400px; overflow-y: auto;}

.matchdata .playerinfo{padding:0;}
.matchdata .playerimg, .matchdata .playerimg figure {display: none;}
}
.link_black_color{
    color: inherit; 
}
.game-searchbar > div > .wrapper, .game-searchbar .cGQDnM .wrapper{border-radius: 6px !important; top:0 !important; box-shadow: none !important; padding:0 0 0 10px;}
.game-searchbar > div > .wrapper:active, .game-searchbar .cGQDnM .wrapper:active, .game-searchbar .cGQDnM .wrapper:focus-within{box-shadow: none !important;}
.game-searchbar .jVORrT {margin: 0;}
.w-full.h-screen.flex.justify-center.items-center{position: fixed; width: 100%; height: 100%; left: 0; top: 0; display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.9); z-index: 12;}

span.pitch_player_position {
    margin: -18px auto 0px;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 2px 0 0;
    font-size: 11px;
    display: block;
    position: relative;
    z-index: 1;
    width: 30px;
    text-align: center;
}
.topplayerlist ul{padding-right: 20px;}

.custom_color1{
    background-color: #2E1760;
}
.custom_color2{
    background-color: #61C9A8;
}
.custom_color3{
    background-color: #5C95FF;
}
.custom_color4{
    background-color: #FF4000;
}
.custom_color5{
    background-color: #22AAA1;
}
.custom_color6{
    background-color: #3423A6;
}
.custom_color7{
    background-color: #63C132;
}
.custom_color8{
    background-color: #FF6700;
}
.custom_color9{
    background-color: #DB2763;
}
.arrowClass{
    cursor: pointer;
}
.color_black_class{
    color:black
}

.addShowCaseDivScroll{max-height: 1000px; overflow-y: auto;}

.introduction_section .heading h2 {
    color: #fff;
    font-size: 40px;
    line-height: 39px;
    margin: 0 0 25px;
    text-align: center;
}

.minidashboard .counter1{width: 100%; padding: 10px 10px;}
.minidashboard .counter1 div.showtime{display: flex; padding:15px 0 0 0;}
.minidashboard .counter1 .arrowClass{font-size: 12px; line-height: 12px; cursor: pointer; position: relative; margin: 0; text-align: center;color: #000000;}
.minidashboard .counter1 .arrowClass:after{position: absolute; content: ""; margin-left: 10px; width: 0; height: 0; top: 4px; right: auto; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #000000;}	
.minidashboard .counter1 div{display: table-column; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.counter1 {
    background-color: #FFD43C;
    padding: 0 30px;
}
.counter1 .arrowClass {font-size: 15px; line-height: 22px; text-align: center; color: #000000; margin: 0 0 5px;}
.custom_dd_class{
    background:none !important;
    border:none !important;
}